/* eslint-disable jsx-a11y/heading-has-content */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import Swal from "sweetalert2";
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import API_ASISTENCIA from '../../../componentes/config/apisAsistencia_Variables';
import API_PERSONALSEGURIDAD from '../../../componentes/config/apisPersonalS_Variables';
import DetalleControl from './AsistenciaUsuario/DetalleControl';
//Función que Trae la fecha actual y la transforma en formato ddmmaaaa 
const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
function AsistenciasUsuario({ showModalTablaMes, onClose, documento }) {
  //Declaración de constantes y sus estados
  const [asistencias, setAsistencias] = useState([]);
  const [nombres, setNombres] = useState("");
  const [apellidoPaterno, setApellidoPaterno] = useState("")
  const [apellidoMaterno, setApellidoMaterno] = useState("")
  const [tipofiltro, setTipoFiltro] = useState("HOY")
  const [loadingFiltro, setLoadingFiltro] = useState(false)
  const [mes, setMes] = useState("30062024")
  //Estado de fecha , trae la fecha de hoy como dato inicial y lo transforma con la función formatDate
  const [fecha, setFecha] = useState(formatDate(new Date()))
  const [modalVisible, setModalVisible] = useState(false);
  const [descripcionModal, setDescripcionModal] = useState('');
  //Función que Cambia la fecha en el input de buscar por fecha 
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  const [modalImagen, setModalImagen] = useState(false);
  const [selectedAsistencia, setSelectedAsistencia] = useState(null);
  const [modalInfoVisible, setModalInfoVisible] = useState(false);  
  const ImagenError= 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.webp'
  const handleChangefecha = (event) => {
    const date = event.target.value;
    setFecha(date);
  };
  const handleDetalleClick = (asistencia) => {
    setSelectedAsistencia(asistencia);
    setModalInfoVisible(true);
  };
  const handlePreview = async (imageId) => {
    try {
      const response = await axios.get(API_PERSONALSEGURIDAD['ImagenAsistencia'](imageId));
      
      // Asume que response.data.images[0] es la cadena base64
      const base64 = response.data.images[0];

      // Crear una URL de datos a partir de la cadena base64
      const imageUrl = `data:image/jpeg;base64,${base64}`;

      // Establecer la imagen seleccionada y mostrar el modal
      setImagenSeleccionada(imageUrl);
      setModalImagen(true);
    } catch (error) {
      setImagenSeleccionada(ImagenError)
      setModalImagen(true);

    }
  };
  //Función que regresa la fecha al dia anterión (para botón de buscar por fecha)
  const handlePrevDay = () => {
    try {
      const day = Number(fecha.substring(0, 2));
      const month = Number(fecha.substring(3, 5)) - 1; 
      const year = Number(fecha.substring(6, 10));
      const currentDate = new Date(year, month, day);
      currentDate.setDate(currentDate.getDate() - 1); 
      const formattedDate = currentDate.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      setFecha(formattedDate);
    } catch (error) {
      console.error('Error en handlePrevDay:', error);
    }
  };
  //Función que regresa la fecha al dia siguiente (para botón de buscar por fecha)
  const handleNextDay = () => {
    try {
      const day = Number(fecha.substring(0, 2));
      const month = Number(fecha.substring(3, 5)) - 1; 
      const year = Number(fecha.substring(6, 10));
      const currentDate = new Date(year, month, day);
      currentDate.setDate(currentDate.getDate() + 1); 
      const formattedDate = currentDate.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      setFecha(formattedDate);
    } catch (error) {
      console.error('Error en handleNextDay:', error);
    }
  };
  //Función para cambiar el tipo de filtro (HOY , FECHA , MES)
  const handleTipoFiltroChange = (tipo) => {
    setLoadingFiltro(true);
    setTimeout(() => {
      setTipoFiltro(tipo);
      setLoadingFiltro(false)
    }, 500);
  };
  //Función para actualizar el estado de mes (cambiarle el mes)
  const handleChangemes = (event) => {
    const selectedValue = event.target.value;
    setMes(selectedValue);
  };
  //Ejecuta la funcion para obtener los datos del usuario por su dni y actualiza por cada digito de dni que ingrese
  useEffect(() => {
    const obtenerDatosporDNI = async (documento) => {
      if (showModalTablaMes) {
      setLoadingFiltro(true);
      try {
        //SOLICITUD PARA OBTENER LOS DATOS
        const datosUrl = API_ASISTENCIA['serenosPorDNI'](documento);
        const respuestadatos = await axios.get(datosUrl);
        console.log(respuestadatos)
        //ACTUALIZA LOS ESTADOS PARA MOSTRAR LOS DATOS DEL USUARIO
        setNombres(respuestadatos.data.SERE_chNOM);
        setApellidoPaterno(respuestadatos.data.SERE_chAPEPAT);
        setApellidoMaterno(respuestadatos.data.SERE_chAPEMAT);
        //SOLICITUD PARA OBTENER SU ROL
        const apiUrl = `https://api01.cloudlab.net.pe/pi0302bh1_asistencia_listget/?VCODBUS=DNI${tipofiltro}&VSERE_P_chDNI=${documento}&VFECHA=${
          tipofiltro === 'MES' ? mes : tipofiltro === 'FECHA' ? fecha.split('/').join('') : '%20'
        }`;        
        const respuesta = await axios.get(apiUrl);
        //ACTUALIZA EL ESTADO DE LA CONST ASISTENCIAS Y ALMACENA EL ARRAY DE DATOS
        setAsistencias(respuesta.data);
        console.log(respuesta.data)
        setLoadingFiltro(false)
      } catch (error) {
        //SI EL DOCUMENTO ES INCORRECTO SE MUESTRA UN ERROR Y SE CIERRA EL MODAL
        onClose();
        Swal.fire({
          icon: 'error',
          title: 'Documento Incorrecto',
          text: 'No se encontró el Documento',
        });
        setNombres("");
        setApellidoPaterno("");
        setApellidoMaterno("");
        
        
        console.error('Error al obtener los datos:', error);
      }
    }
    };
    obtenerDatosporDNI(documento);
  }, [documento, tipofiltro, mes, fecha,showModalTablaMes]);
  //Switch para darle el nombre de manera visual al tregistro seleccionado
  const gettiporegistro = (tiporegistro) => {
    switch (tiporegistro) {
      case 'Asis'||'asis' :
        return 'Asistencia';
      case 'Inasis':
        return 'Inasistencia';
      case 'Acti':
        return 'Actividad';
      case 'Rad':
        return 'Radio';
      case 'Mot':
        return 'Moto';
      case 'Camio':
        return 'Camioneta';
      case 'Bic':
        return 'Bicicleta';
      case 'SSD':
        return 'Sueldo Sin Deposito';
      case 'SUP':
        return 'Sueldo Pagado';
      case 'PF':
        return 'Puesto Tactico';
      case 'PT':
        return 'Puesto Tactico';
      case 'Onom':
        return 'Onomástico';
      case 'PermP':
        return 'Permiso Personal';
      case 'PermS':
        return 'Permiso por Salud';
      case 'Vac':
        return 'Vacaciones';
      case 'De':
        return 'Descanso';
      default:
        return '';
    }
  };
  //Función para el datatable , destruye el datatable al cerrar el modal para así no haya falla al abrir uno nuevo
  useEffect(() => {
    if (!loadingFiltro) {
      const table = $('#tablaregistrosdni').DataTable();
      if ($.fn.DataTable.isDataTable(table)) {
        table.destroy();
      }
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          "sProcessing": "Procesando...",
          "sLengthMenu": "Mostrar _MENU_ registros",
          "sZeroRecords": "No se encontraron resultados",
          "sEmptyTable": "Ningún dato disponible en esta tabla",
          "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
          "sInfoPostFix": "",
          "sSearch": "Buscar:",
          "sUrl": "",
          "sInfoThousands": ",",
          "sLoadingRecords": "Cargando...",
          "oPaginate": {
            "sFirst": "Primero",
            "sLast": "Último",
            "sNext": "Siguiente",
            "sPrevious": "Anterior"
          },
          "oAria": {
            "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "buttons": {
            "copy": "Copiar",
            "colvis": "Visibilidad"
          }
        }   
      });
      $('#tablaregistrosdni').DataTable();
    }
  }, [loadingFiltro, showModalTablaMes]);
  //Función que Abre el modal con la descripción del registro
  const handleAbrirModal = (descripcion) => {
    setModalVisible(true);
    setDescripcionModal(descripcion);
  };
  //Obtiene la fecha y le da formato dd/mm/aaaa para mostrarse de manera visual
  const formatofecha = (dateStr) => {
    const day = dateStr.substring(0, 2);
    const month = dateStr.substring(2, 4);
    const year = dateStr.substring(4, 8);
    return `${day}/${month}/${year}`;
  };
  const customStyles = {
    content: {
      Width: "100%",
      Height: "100%",
      margin: "auto",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
    },
  };
  const modalImageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
  };

  return (
    <Modal show={showModalTablaMes} onHide={onClose} dialogClassName="modal-xl" centered className='funcionmodal'>
      <Modal.Header closeButton className="bg-primary text-center">
        <Modal.Title style={{ "color": "#fff", "width": "100%" }}>Bitácora del Personal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tabladedatos">

        <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ padding: '5px', width: '100%' }}>
          <input
            type="radio"
            className="btn-check"
            name="options"
            id="option1"
            autoComplete="off"
            checked={tipofiltro === 'HOY'}
            onChange={() => handleTipoFiltroChange('HOY')}
          />
          <label className={`btn ${tipofiltro === 'HOY' ? 'btn-success' : 'btn-secondary'} funcionmodal font-Responsive`} htmlFor="option1">
            Registros de Hoy
          </label>
          <input
            type="radio"
            className="btn-check"
            name="options"
            id="option2"
            autoComplete="off"
            checked={tipofiltro === 'FECHA'}
            onChange={() => handleTipoFiltroChange('FECHA')}
          />
          <label className={`btn ${tipofiltro === 'FECHA' ? 'btn-info' : 'btn-secondary'} funcionmodal font-Responsive`} htmlFor="option2">
            Filtrar por Fecha
          </label>
          <input
            type="radio"
            className="btn-check"
            name="options"
            id="option3"
            autoComplete="off"
            checked={tipofiltro === 'MES'}
            onChange={() => handleTipoFiltroChange('MES')}
          />
          <label className={`btn ${tipofiltro === 'MES' ? 'btn-warning' : 'btn-secondary'} funcionmodal font-Responsive`} htmlFor="option3">
            Filtrar por Mes
          </label>
        </div>
        {loadingFiltro ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {tipofiltro === "MES" ?
              <>
                <div className="form-group funcionmodal">
                  <label htmlFor="">Escoge un Mes:</label>
                  <select
                    className="form-select funcionmodal"
                    name='mes'
                    value={mes}
                    onChange={handleChangemes}>
                    <option value="31012024">Enero</option>
                    <option value="28022024">Febrero</option>
                    <option value="31032024">Marzo</option>
                    <option value="30042024">Abril</option>
                    <option value="31052024">Mayo</option>
                    <option value="30062024">Junio</option>
                    <option value="31072024">Julio</option>
                    <option value="31082024">Agosto</option>
                    <option value="30092024">Septiembre</option>
                    <option value="31102024">Octubre</option>
                    <option value="30112024">Noviembre</option>
                    <option value="31122024">Diciembre</option>
                  </select>
                </div>
                <br />
              </>
              : tipofiltro === "FECHA" ?
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <button style={{ border: 'none', background: 'none', cursor: 'pointer' }} onClick={handlePrevDay}><i class="fa-solid fa-circle-arrow-left"></i></button>
                    <input
                      className="form-control funcionmodal"
                      type="text"
                      value={fecha}
                      style={{
                        width: '60%',
                        margin: '0 20px',
                        border: '2px solid black',
                        textAlign: 'center'
                      }}
                      onChange={handleChangefecha}
                    />
                    <button style={{ border: 'none', background: 'none', cursor: 'pointer' }} onClick={handleNextDay}><i class="fa-solid fa-circle-arrow-right"></i></button>
                  </div>
                  <br />
                </>
                :
                <></>
            }
            <div className="table-responsive overflow-auto text-center">
              <table
                id="tablaregistrosdni"
                className="table table-striped">
                <thead>
                  <tr>
                    <th className='text-center'>Fecha</th>
                    <th className='text-center'>Hora</th>
                    <th className='text-center'>Tipo Registro</th>
                    <th className='text-center'>Tipo</th>
                    <th className='text-center'>Registrante</th>
                    <th className='text-center'>Foto</th>
                    <th className='text-center'>Descripción</th>
                  </tr>
                </thead>
                <tbody>
                  {asistencias.map((asistencia, index) => (
                    <tr key={index}>
                      <td>{formatofecha(asistencia.ASIS_chFEC)}</td>
                      <td>{asistencia.ASIS_chHOR}</td>
                      <td>{gettiporegistro(asistencia.ASIS_chTIPREG)}</td>
                      <td style={{ backgroundColor: asistencia.ASIS_chEntSal === 'E' || asistencia.ASIS_chEntSal === 'R' ? 'rgb(73, 224, 73)' : asistencia.ASIS_chEntSal === 'P' || asistencia.ASIS_chEntSal === 'O' ? '#2d2f30' : 'rgb(242, 61, 61)', color: asistencia.ASIS_chEntSal === 'P' || asistencia.ASIS_chEntSal === 'O' ? 'white' : 'black' }}>
                        {asistencia.ASIS_chEntSal === 'E' ? 'Entrada' : asistencia.ASIS_chEntSal === 'R' ? 'Recurso' : asistencia.ASIS_chEntSal === 'P' ? 'Pago' : asistencia.ASIS_chEntSal === 'O' ? 'Otro' : 'Salida'}
                      </td>
                      <td>{asistencia.ASIS_IN_idpk_respondoc === null ? "No registra" : asistencia.ASIS_IN_idpk_respondoc}</td>
                      <td>{asistencia.ASIS_P_inID ? 
                          <button
                            className="btn btn-success btn-sm"
                            style={{ fontSize: "11px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePreview(asistencia.ASIS_P_inID);
                            }}
                          >
                            Ver Foto
                          </button>:
                          <span style={{ fontSize: "11px" }}>No tiene Foto</span>}
                      </td>
                      <td>
                        <button
                            onClick={() => handleDetalleClick(asistencia)} 
                            style={{cursor:'pointer',fontSize:'10px'}}
                              className="btn btn-warning btn-sm m-1"
                            >
                              Ver Detalle
                            </button>
                        </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {modalVisible && (
                <Modal show={modalVisible} onHide={() => setModalVisible(false)} size={'sm'} centered>
                  <Modal.Header closeButton>
                    <div style={{ maxWidth: '90%' }}>
                      <p style={{ overflowWrap: 'break-word' }}>{descripcionModal}</p>
                    </div>
                  </Modal.Header>
                </Modal>
              )}
              {modalImagen && (
                    <Modal
                      show={modalImagen}
                      onHide={() => setModalImagen(false)}
                      style={customStyles}
                      centered
                    >
                      <Modal.Header closeButton>
                      <div>
                        <img
                          src={imagenSeleccionada}
                          alt="Previsualización"
                          style={modalImageStyle}
                          onError={(e) => {
                            e.target.src = 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.webp';
                          }}
                        />
                      </div>
                  </Modal.Header>
                      
                    </Modal>
                  )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onClose} >
          Cerrar
        </Button>
      </Modal.Footer>
      <DetalleControl
        show={modalInfoVisible}
        onClose={() => setModalInfoVisible(false)}
        asistencia={selectedAsistencia}
      />
    </Modal>
  )
}
export default AsistenciasUsuario;