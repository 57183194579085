/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import axios from "axios";
import './Pages/CSS/estilos.css'

import Cookies from "js-cookie";
import CryptoJS from "crypto-js";  // Ensure that the import is correct
function App() {
  const [dni, setDni] = useState("");
  const [rol, setRol] = useState("");
  const [clave, setClave] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fechaRol, setFechaRol] = useState(null);
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        console.log(data.ip)
        Cookies.set("miip", data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };
    fetchIp();
  }, []);
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('es-ES');
    setFechaRol(formattedDate);


  }, []);
  const encryptPassword = (password) => {
    // Use a secret key for encryption
    const secretKey = "your-secret-key";
    const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
    return encryptedPassword;
  };
  const obtenerDatos = async () => {
    try {
      const respuesta = await axios.get(
        `https://api01.cloudlab.net.pe/pi124_M_MAES_SEREN_LOGIN/${dni}/${clave}/`
      );
      const rol = respuesta.data.SERE_chRol;
      const nombre = respuesta.data.SERE_chNOM;
      const apellidopat = respuesta.data.SERE_chAPEPAT;
      const apellidomat = respuesta.data.SERE_chAPEMAT;
      const dnilogin = respuesta.data.SERE_P_chDNI;
      const CONTRASENIA = respuesta.data.SERE_chCLAVE;
      const encryptedClave = encryptPassword(CONTRASENIA);
      var datos = {
        nombre: nombre,
        apellidopat: apellidopat,
        apellidomat: apellidomat,
        dnilogin: dnilogin,
        clave: encryptedClave
      };
      var datosString = JSON.stringify(datos);
      localStorage.setItem("datos", datosString);
      setRol(rol);
      Cookies.set("rol", rol);
      Cookies.set("nombre", nombre);
      Cookies.set("apellidopat", apellidopat);
      Cookies.set("apellidomat", apellidomat);
      Cookies.set("dnilogin", dnilogin);
      if (respuesta.status === 200) {
        try {
          const respuestarol1 = await axios.get(
            `https://api01.cloudlab.net.pe/pi0401_RolAsig_listget/?VSERE_P_chDNI=${dni}&vcodopc_rol=ROL01`
          );
          const respuestarol2 = await axios.get(
            `https://api01.cloudlab.net.pe/pi0401_RolAsig_listget/?VSERE_P_chDNI=${dni}&vcodopc_rol=ROL02`
          );
          let codrol1;
          if (respuestarol1.data.length > 0 && respuestarol1.data[0].cata_CH_Cod_rolserv) {
            codrol1 = respuestarol1.data[0].cata_CH_Cod_rolserv;
          } else if (respuestarol2.data.length > 0) {
            codrol1 = respuestarol2.data[0].cata_CH_Cod_rolserv;
          }
          let codrol2, codrol3;
          // Buscar el código de rol diferente de codrol1 en la respuesta de la segunda API
          if (respuestarol2.data[0].cata_CH_Cod_rolserv !== codrol1) {
            codrol2 = respuestarol2.data[0].cata_CH_Cod_rolserv;
          } else if (respuestarol2.data[1].cata_CH_Cod_rolserv !== codrol1) {
            codrol2 = respuestarol2.data[1].cata_CH_Cod_rolserv;
          } else {
            codrol2 = respuestarol2.data[2].cata_CH_Cod_rolserv;
          }
          // Buscar un código de rol diferente de codrol1 y codrol2 en la respuesta de la segunda API
          if (respuestarol2.data[0].cata_CH_Cod_rolserv !== codrol1 && respuestarol2.data[0].cata_CH_Cod_rolserv !== codrol2) {
            codrol3 = respuestarol2.data[0].cata_CH_Cod_rolserv;
          } else if (respuestarol2.data[1].cata_CH_Cod_rolserv !== codrol1 && respuestarol2.data[1].cata_CH_Cod_rolserv !== codrol2) {
            codrol3 = respuestarol2.data[1].cata_CH_Cod_rolserv;
          } else {
            codrol3 = respuestarol2.data[2].cata_CH_Cod_rolserv;
          }
          Cookies.set("codigorol", codrol1);
          Cookies.set("codigorol2", codrol2);
          Cookies.set("codigorol3", codrol3);
          const idrol = respuestarol1.data[0].cata_IN_IDPK_rolserv;
          const horario = respuestarol1.data[0].cata_CH_horario_rolserv;
          Cookies.set("idrol", idrol);
          Cookies.set("horario", horario);
        } catch (error) {
          console.error("Error al obtener el rol:", error);
        }
        window.location.replace('/principal');
      } else {
        // Handle unsuccessful login
        setErrorMessage("Los datos ingresados son incorrectos");
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      setErrorMessage("!Los datos ingresados son incorrectos");
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    obtenerDatos();
  };
  return (
    <div className="global" >
      <header>
      </header>
      {errorMessage && <div className="error-message2">
        {errorMessage}</div>}
      <div className="card">
      <img
          src={require("./componentes/logo_victoria.png")}
          className="loginlogo"
          style={{ margin: 'auto', width: '100%', height: '120px', padding: '15px 50px' }}
          alt="Logo"
        />
                <h2
          style={{
            width: '100%',
            fontSize: '13px',
            textAlign: 'center',
            padding: '10px',
            backgroundColor: '#191970',
            color: 'white',
            fontWeight: 'bold'
          }}
        >

          CONTROL DE PERSONAL
        </h2>
        <form onSubmit={handleSubmit} class="form card">
          <div class="card_header d-flex justify-content-center align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z"></path>
            </svg>
            <h1 class="form_heading">Inicio de Sesión</h1>
          </div>
          <div class="field">
            <label className="label">
              DNI:
            </label>
            <input
              className="input"
              type="text"
              value={dni}
              onChange={(e) => setDni(e.target.value)}
              autocomplete="username"

            />
          </div>
          <div class="field">
            <label className="label">
              CONTRASEÑA:
            </label>
            <input
              className="input"
              type="password"
              value={clave}
              onChange={(e) => setClave(e.target.value)}
              autocomplete="current-password"
            />
          </div>
          <div class="field">
            <button type="submit" className="botonlogin"><i class="fas fa-sign-in-alt"></i> INGRESAR</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;