import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import $ from "jquery";
import "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net";
import "datatables.net-bs5";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import API_PERSONALSEGURIDAD from "../../componentes/config/apisPersonalS_Variables";
import CreateBitacora from "./Modals/CreateBitacora";

function BitacoraPersonal({ show, onHideFP, selectedPersona,datos }) {
  const dniusuario = Cookies.get("dnilogin");

  const [faltas, setFaltas] = useState([]);
  const [ModalAddFalta, setModalAddFalta] = useState(false);
  const [ModalEditFalta, setModalEditFalta] = useState(false);
  const [acciones, setAcciones] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAccion, setSelectedAccion] = useState("");
  console.log(selectedAccion)

  const [selectedCategoria, setSelectedCategoria] = useState("");
  console.log(selectedCategoria)

  const [archivo, setArchivo] = useState(null);
  const [annoBitacora, setAnnoBitacora] = useState("2024");
  const [editFormData, setEditFormData] = useState(null);

  const handleFileChange = (event) => {
    const selectedArchivo = event.target.files[0];
    setArchivo(selectedArchivo);
  };

  const initialFormData = {
    histoper_ch_idcodenlafk: selectedPersona || "",
    histoper_in_idaccionesfk: selectedAccion.value,
    histoper_ch_categoriafk: selectedCategoria.value,
    histoper_ch_docnro: "",
    histoper_ch_docanno: "",
    histoper_ch_descrip: "",
    histoper_ch_ausu: dniusuario,
    archifaltas_in_flaeli: "1",
    histoper_ch_titu: "",
    histoper_ch_anno: annoBitacora,
    histoper_ch_nro: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const clearForm = () => {
    setFormData(initialFormData);
    setEditFormData(null);
    setArchivo(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const openModalADDFalta = () => {
    clearForm();
    setModalAddFalta(true);
  };

  const openModalEditFalta = (falta) => {
    setEditFormData(falta);
    setFormData({
      ...falta,
      histoper_ch_idcodenlafk: selectedPersona,
      histoper_ch_ausu: dniusuario,
    });
    setModalEditFalta(true);
  };

  const HandleGenerateCode = async () => {
    try {
      const datos = {
        AlertConfDoc_CH_annoDoc: annoBitacora,
      };
      const response = await axios.put(API_PERSONALSEGURIDAD["generarCode"], datos);
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        histoper_ch_nro: 'BIT' + annoBitacora + " - " + response.data.AlerConfDoc_ch_nroDoc
      }));
    } catch (error) {
      console.error("Error fetching code:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filteredData = {
      histoper_in_idpk: formData.histoper_in_idpk || 0,
      histoper_ch_idcodenlafk: formData.histoper_ch_idcodenlafk,
      histoper_in_idaccionesfk: formData.histoper_in_idaccionesfk,
      histoper_ch_categoriafk: formData.histoper_ch_categoriafk,
      histoper_ch_docnro: formData.histoper_ch_docnro,
      histoper_ch_docanno: formData.histoper_ch_docanno,
      histoper_ch_descrip: formData.histoper_ch_descrip,
      histoper_ch_ausu: formData.histoper_ch_ausu,
      archifaltas_in_flaeli: formData.archifaltas_in_flaeli,
      histoper_ch_titu: formData.histoper_ch_titu,
      histoper_ch_nro: formData.histoper_ch_nro,
      histoper_ch_anno: formData.histoper_ch_anno
    };
    console.log("Datos enviados:", filteredData);

    try {
      const responseFaltas = editFormData
        ? await axios.put(
            `https://api01.cloudlab.net.pe/pi20b_histoper_put/?id_histoper=${editFormData.ACCI_P_inID}`,
            filteredData
          )
        : await axios.post(
            API_PERSONALSEGURIDAD["postFaltas"](selectedPersona),
            filteredData
          );
      if (responseFaltas.status >= 200 && responseFaltas.status < 300) {
        if (archivo) {
          const formDataArchivo = new FormData();
          formDataArchivo.append("file", archivo);
          const selectedDocumento = selectedPersona;
          const tipoarchivo = "files";
          const clasearchivo = "bitacora";
          const estadofoto = "1";

          const responseArchivo = await axios.post(
            API_PERSONALSEGURIDAD["postfotoarchivo"](
              tipoarchivo,
              selectedDocumento,
              clasearchivo,
              estadofoto
            ),
            formDataArchivo
          );

          if (responseArchivo.status >= 200 && responseArchivo.status < 300) {
            Swal.fire({
              icon: "success",
              title: "Registro Exitoso",
              text: "La falta y su archivo se registraron correctamente.",
            }).then(() => {
              setModalAddFalta(false);
              setModalEditFalta(false);
              clearForm();
            });
            await fetchFaltas(selectedPersona);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error al registrar el archivo",
              text: "No se pudo subir el archivo (pi20a-uplo)",
            });
          }
        } else {
          Swal.fire({
            icon: "success",
            title: "Registro Exitoso",
            text: "La falta se registró correctamente.",
          }).then(() => {
            setModalAddFalta(false);
            setModalEditFalta(false);
            clearForm();
          });
          await fetchFaltas(selectedPersona);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al registrar la falta",
          text: "No se pudo registrar la falta (pi20a_hist)",
        });
      }
    } catch (error) {
      console.error("Error en el proceso:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error en el servidor.",
      });
    }
  };

  const fetchFaltas = async (selectedPersona) => {
    try {
      setLoading(true);

      const response = await axios.get(
        API_PERSONALSEGURIDAD["listarfaltas"](selectedPersona)
      );
      setFaltas(response.data.data);
    } catch (error) {
      console.error("Error al obtener las faltas:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAcciones = async () => {
      try {
        const response = await axios.get(
          API_PERSONALSEGURIDAD["listadoacciones"]
        );
        setAcciones(response.data);
      } catch (error) {
        console.error("Error fetching empresas:", error);
      }
    };
    const fetchCategorias = async () => {
      try {
        const response = await axios.get(
          API_PERSONALSEGURIDAD["listadocategorias"]
        );
        setCategorias(response.data);
      } catch (error) {
        console.error("Error fetching empresas:", error);
      }
    };
    fetchAcciones();
    fetchCategorias();
  }, []);

  useEffect(() => {
    setLoading(true);
    setFormData(initialFormData);
    fetchFaltas(selectedPersona);
  }, [selectedPersona]);

  useEffect(() => {
    if (!loading) {
      const table = $("#example2").DataTable();
      if ($.fn.dataTable.isDataTable(table)) {
        table.destroy();
      }
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          sProcessing: "Procesando...",
          sLengthMenu: "Mostrar _MENU_ registros",
          sZeroRecords: "No se encontraron resultados",
          sEmptyTable: "Ningún dato disponible en esta tabla",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
          sInfoPostFix: "",
          sSearch: "Buscar:",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando...",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending:
              ": Activar para ordenar la columna de manera ascendente",
            sSortDescending:
              ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copy: "Copiar",
            colvis: "Visibilidad",
          },
        },
      });

      $("#example2").DataTable();
    }
  }, [loading, show]);

  return (
    <>
      <Modal show={show} onHide={onHideFP} dialogClassName="modal-custom" centered style={{ fontSize: '12px' }}>
        <Modal.Header closeButton className="bg-danger">
          <Modal.Title
            className="font-Responsive"
            style={{ color: "#fff", textAlign: "center",fontSize:'18px' }}
          >
            Bitácora de Personal de {datos}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bitacora">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            </div>
          ) : (
            <div
              className="card card-bitacora col-sm-12"
              style={{ maxWidth: "100%" }}
            >
              <div className="card-body">
                <div className="card-toolbar">
                  <button
                    type="button"
                    className="btn btn-success mb-1 font-Responsive"
                    title="Registrar Falta"
                    onClick={() => openModalADDFalta(selectedPersona)}
                  >
                    <strong>Registrar Bitácora </strong>
                    <i className="fa-solid fa-folder-plus"></i>
                  </button>
                </div>
                <hr style={{ width: "100%" }} />
                <div className="table-responsive-xl">
                  <table
                    id="example2"
                    className="table table-striped text-center"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th className="text-center">Título</th>
                        <th className="text-center">Acción</th>
                        <th className="text-center">Categoría</th>
                        <th className="text-center">Año</th>
                        <th className="text-center">Numero de Documento</th>
                        <th className="text-center">Gravedad</th>
                        <th className="text-center">Tipo</th>
                        <th className="text-center">Medida</th>
                        <th className="text-center">Registrante</th>
                        <th className="text-center">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                    {faltas !== null &&
                        faltas.map((index) => (
                          <tr key={index.data.histoper_ch_nro}>
                            <td>{index.data.histoper_ch_titu}</td>
                            <td>{index.data.ACCI_chDES}</td>
                            <td>
                              {index.data.histoper_ch_categoriafk
                                ? index.data.histoper_ch_categoriafk
                                : "No registra"}
                            </td>
                            <td style={{width: 100}}>
                              {index.data.histoper_ch_anno
                                ? index.data.histoper_ch_anno
                                : "No registra"}
                            </td>
                            <td>
                              {index.data.histoper_ch_nro
                                ? index.data.histoper_ch_nro
                                : "No registra"}
                            </td>
                            <td>{index.data.FALT_chDES}</td>
                            <td>{index.data.TIPO_chDES}</td>
                            <td>
                              {index.data.MEDI_chDES }
                            </td>
                            <td>{index.data.histoper_ch_ausu}</td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => openModalEditFalta(index.data)}
                              >
                                Editar
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={onHideFP}
            className="font-Responsive"
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <CreateBitacora
        show={ModalAddFalta || ModalEditFalta}
        onHide={() => {
          setModalAddFalta(false);
          setModalEditFalta(false);
        }}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        acciones={acciones}
        selectedAccion={selectedAccion}
        setSelectedAccion={setSelectedAccion}
        handleFileChange={handleFileChange}
        handleGenerateCode={HandleGenerateCode}
        selectedCategoria={selectedCategoria}
        setSelectedCategoria={setSelectedCategoria}
        categorias={categorias}
      />
    </>
  );
}

export default BitacoraPersonal;