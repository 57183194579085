import { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import Select from "react-select";
import Swal from 'sweetalert2';
import API_ASISTENCIA from '../../../componentes/config/apisAsistencia_Variables';
import credenciales from '../../../componentes/config/credenciales';

const ModalRegistroCapacidad = ({ showModal, handleClose }) => {
  const [fechaHora, setFechaHora] = useState('');
  const [funcion, setFuncion] = useState(null);
  const [total, setTotal] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState('');
  const [funciones, setFunciones] = useState([]);  
  const [tablaDatos, setTablaDatos] = useState([]); 
  // Función para obtener las funciones de la API
  const fetchFunciones = async () => {

    const username = credenciales.usuario;
    const password = credenciales.contrasena;
    const encodedCredentials = btoa(`${username}:${password}`);

    try {
      const response = await fetch(API_ASISTENCIA.funciones, {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        const result = await response.json();
        const opcionesFunciones = result.funciones.map(funcion => ({
          value: funcion,
          label: funcion,
        }));
        setFunciones(opcionesFunciones);
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Hubo un error al obtener las funciones');
        Swal.fire('Error', errorData.error || 'Error al obtener las funciones', 'error');
      }
    } catch (err) {
      setError('Hubo un error al conectar con la API');
      Swal.fire('Error', 'Hubo un error al conectar con la API', 'error');
      console.error('Error:', err);
    }
  };

  useEffect(() => {
    fetchFunciones();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (tablaDatos.length === 0) {
      setError('Debe agregar al menos un registro.');
      return;
    }

    const data = tablaDatos.map(item => ({
      CAP_dtFECHOR: item.fechaHora,
      CAP_chFUN: item.funcion,
      CAP_IN_TOTAL: item.total,
      CAP_CH_EST: 'activo',
    }));

    const username = credenciales.usuario;
    const password = credenciales.contrasena;
    const encodedCredentials = btoa(`${username}:${password}`);

    try {
      const response = await fetch(API_ASISTENCIA.capacidadAsisCreate, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${encodedCredentials}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        setMensaje('Registros creados con éxito');
        setError('');
        Swal.fire('Éxito', 'Registros creados con éxito', 'success');
        // Limpiar el formulario y la tabla después de la respuesta
        setFechaHora('');
        setFuncion(null);
        setTotal('');
        setTablaDatos([]);
        handleClose();
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Hubo un error al crear los registros');
        setMensaje('');
        Swal.fire('Error', errorData.error || 'Error al crear los registros', 'error');
      }
    } catch (err) {
      setError('Hubo un error al conectar con la API');
      setMensaje('');
      Swal.fire('Error', 'Hubo un error al conectar con la API', 'error');
      console.error('Error:', err);
    }
  };

  const handleFechaChange = (e) => {
    const fechaSeleccionada = e.target.value;
    const fechaConHora = `${fechaSeleccionada}T00:00`;
    setFechaHora(fechaConHora);
  };

  const handleAgregar = () => {
    if (!fechaHora || !funcion || !total) {
      setError('Todos los campos son obligatorios.');
      return;
    }

    const nuevoRegistro = {
      fechaHora,
      funcion: funcion.value,
      total,
    };

    setTablaDatos([...tablaDatos, nuevoRegistro]);
    setFechaHora('');
    setFuncion(null);
    setTotal('');
    setError('');
  };

  const handleLimpiarFila = (index) => {
    const nuevaTablaDatos = tablaDatos.filter((_, i) => i !== index);
    setTablaDatos(nuevaTablaDatos);
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton className="bg-success text-white">
        <Modal.Title>REGISTRO DE CONTROL DE CAPACIDAD</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-12 col-lg-6 mb-2">
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-lg-12">
                    <Form.Group controlId="formFuncion">
                      <Form.Label>Función:</Form.Label>
                      <Select 
                        options={funciones} 
                        value={funcion} 
                        onChange={setFuncion} 
                        placeholder="Seleccione una función" 
                        isClearable 
                        className="form-control-sm" 
                      />
                    </Form.Group>
                </div>
              </div>
              <div className="row">
               <div className="col-12 col-lg-6">
                  <Form.Group controlId="formFechaHora">
                    <Form.Label>Fecha:</Form.Label>
                    <Form.Control 
                      type="date"
                      value={fechaHora ? fechaHora.split('T')[0] : ''}
                      onChange={handleFechaChange} 
                      required
                      className="form-control-sm"
                    />
                  </Form.Group>
               </div>
               <div className="col-12 col-lg-6">
                  <Form.Group controlId="formTotal">
                    <Form.Label>Total:</Form.Label>
                    <Form.Control 
                      type="number" 
                      value={total} 
                      onChange={(e) => setTotal(e.target.value)} 
                      required
                      className="form-control-sm"
                    />
                  </Form.Group>
               </div>
              </div>

    

                  {mensaje && <p style={{ color: 'green' }}>{mensaje}</p>}
                  {error && <p style={{ color: 'red' }}>{error}</p>}

                  <Button variant="primary" onClick={handleAgregar} className="mt-2">
                    Agregar
                  </Button>
            </Form>
          </div>

          <div className="col-12 col-lg-6">
            <div className="table-responsive">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Función</th>
                    <th>Total</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {tablaDatos.map((item, index) => (
                    <tr key={index}>
                      <td>{item.fechaHora.split('T')[0]}</td>
                      <td>{item.funcion}</td>
                      <td>{item.total}</td>
                      <td>
                        <Button 
                          variant="danger" 
                          onClick={() => handleLimpiarFila(index)} 
                          className="mt-2"
                        >
                          Limpiar
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <Button variant="success" type="submit" className="mt-2" onClick={handleSubmit}>
              Enviar
            </Button>
          </div>

        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRegistroCapacidad;
