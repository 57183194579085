const API_PERSONALSEGURIDAD = {
    listarPersonal: 'https://api01.cloudlab.net.pe/pi120_M_MAES_SEREN_LIST/?limit=1000',
    funciones: 'https://api01.cloudlab.net.pe/api50h_param_read_funasis_get/',
    descargararchivo: (archivoId) => `https://api01.cloudlab.net.pe/pi20b-download-archisql/files/${archivoId}/`,
    descargarimagen: (imageId) =>`https://api01.cloudlab.net.pe/pi20b-download-archisql/img/${imageId}/`,
    ListadogeneralFaltas: 'https://api01.cloudlab.net.pe/pi20d_histoper_list/?skip=0&limit=1000',
    mostrarfotodeusuario: (selectedFichaPersona) => `https://api01.cloudlab.net.pe/pi20b100-download-archisql/${selectedFichaPersona}/fotoperfil/1/`,
    generarPDF: (dni) =>  `https://api01.cloudlab.net.pe/pi801_repor_fichaPer_pdf?dni_personal=${dni}`,
    serenosPorDNI: (selectedFichaPersona) => `https://api01.cloudlab.net.pe/pi121_M_MAES_SEREN_DNIQR/${selectedFichaPersona}/1/`,
    listarfaltas: (selectedPersona) => `https://api01.cloudlab.net.pe/pi20i_histoper_list_q/?vtipobusqueda=bpordni&vvalorbuscado1=${selectedPersona}`,
    postFaltas: (selectedPersona) => `https://api01.cloudlab.net.pe/pi20a_histoper_add/${selectedPersona}/`,
    listadoacciones: 'https://api01.cloudlab.net.pe/pi20b_accionesper_list/?skip=0&&limit=1000',
    listadocategorias: 'https://api01.cloudlab.net.pe/api50f_param_read_tipcatbita_get/',
    postfotoarchivo: (tipoarchivo,selectedDocumento,clasearchivo,estadofoto) => `https://api01.cloudlab.net.pe/pi20a-upload-archisql/${tipoarchivo}/${selectedDocumento}/${clasearchivo}/${estadofoto}/`,
    putUsuario: (dni) => `https://api01.cloudlab.net.pe/pi123_M_MAES_SEREN_ACT/${dni}/`,
    postUsuario: `https://api01.cloudlab.net.pe/pi122_M_MAES_SEREN_ADD/`,
    generarCode:'https://api01.cloudlab.net.pe/mas200_confdocnumput/?vconfigdoc_id=BITA01DOC',
    ImagenAsistencia: (imagenId) => `https://api01.cloudlab.net.pe/pi14filjson_iddownload_fil_asissql/?vopc_todos=bporid&vvalor_buscado=${imagenId}`
  };

  
  export default API_PERSONALSEGURIDAD;