import { useEffect, useState } from 'react';
import { Button, Modal, Card, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";

function DetalleControl({ show, onClose, asistencia }) {
  const [imageSrc, setImageSrc] = useState(null);
  const defaultImage = 'https://via.placeholder.com/150'; // URL de la imagen por defecto

  useEffect(() => {
    if (asistencia && asistencia.ASIS_P_inID) {
      const fetchImage = async () => {
        try {
          const response = await axios.get(`https://api01.cloudlab.net.pe/pi14filjson_iddownload_fil_asissql/?vopc_todos=bporid&vvalor_buscado=${asistencia.ASIS_P_inID}`);
          if (response.status === 200 && response.data.images) {
            const base64Image = response.data.images[0];
            setImageSrc(`data:image/jpeg;base64,${base64Image}`);
          } else {
            setImageSrc(defaultImage);
          }
        } catch (error) {
          console.error('Error al obtener la imagen:', error);
          setImageSrc(defaultImage);
        }
      };
      fetchImage();
    } else {
      setImageSrc(defaultImage);
    }
  }, [asistencia]);

  const gettiporegistro = (tiporegistro) => {
    switch (tiporegistro) {
      case "Asis":
        return "Asistencia";
      case "Inasis":
        return "Inasistencia";
      case "Acti":
        return "Actividad";
      case "Tar":
        return "Tardanza";
      case "Rad":
        return "Radio";
      case "Mot":
        return "Moto";
      case "Camio":
        return "Camioneta";
      case "Bic":
        return "Bicicleta";
      case "SSD":
        return "Sueldo Sin Deposito";
      case "SUP":
        return "Sueldo Pagado";
      case "PF":
        return "Puesto Fijo";
      case "PT":
        return "Puesto Táctico";
      case "Onom":
        return "Onomástico";
      case "PermP":
        return "Permiso Personal";
      case "PermS":
        return "Permiso por Salud";
      case "Vac":
        return "Vacaciones";
      case "De":
        return "Descanso";
      default:
        return "";
    }
  };

  const formatofecha = (dateStr) => {
    const day = dateStr.substring(0, 2);
    const month = dateStr.substring(2, 4);
    const year = dateStr.substring(4, 8);
    return `${day}/${month}/${year}`;
  };

  return (
    <Modal show={show} onHide={onClose} centered size="lg" className='font-Responsive'>
      <Modal.Header closeButton className="bg-warning text-black shadow-sm">
        <Modal.Title className="fw-bold font-Responsive">Información de Bitácora</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
      {asistencia && (
          <div className="detalleControl">
            <div className="FilaFotos-detalleControl">
              <img
                src={`https://api01.cloudlab.net.pe/pi20b100-download-archisql/${asistencia.SERE_P_chDNI}/fotoperfil/1/`}
                alt="Foto del usuario"
                style={{
                  width: "200px",
                  height: "100%",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "3px solid #ddd",
                  objectFit: "cover",
                  marginBottom: "20px",
                }}
                onError={(e) => {
                  e.target.src = defaultImage;
                }}
              />
              <img
                src={imageSrc || defaultImage}
                alt="Foto del usuario"
                style={{
                  width: "200px",
                  height: "100%",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "3px solid #ddd",
                  objectFit: "cover",
                  marginBottom: "20px",
                }}
                onError={(e) => {
                  e.target.src = defaultImage;
                }}
              />
            </div>
            <div className="FilaFotos-detalleControl">
              <Card
                className="shadow-sm border-0"
                style={{ borderRadius: "10px", marginTop: "20px" }}
              >
               <Card.Body>
                  <Card.Title className="fw-semibold text-primary">Datos del Personal</Card.Title>
                  <Card.Text><strong>Nombres y Apellidos :</strong> {asistencia.SERE_chNOM} {asistencia.SERE_chAPEPAT} {asistencia.SERE_chAPEMAT}</Card.Text>
                  <Card.Text><strong>Documento:</strong> {asistencia.SERE_P_chDNI}</Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="shadow-sm border-0 mb-3"
                style={{ borderRadius: "10px", textAlign: "left" }}
              >
                <Card.Body>
                  <Card.Title className="fw-semibold text-primary">Detalles del Registro</Card.Title>
                  <Card.Text><strong>Fecha Registro:</strong> {formatofecha(asistencia.ASIS_chFEC)}</Card.Text>
                  <Card.Text><strong>Hora de Registro:</strong> {asistencia.ASIS_chHOR}</Card.Text>
                  <Card.Text><strong>Tipo Registro:</strong> {gettiporegistro(asistencia.ASIS_chTIPREG)}</Card.Text>
                  <Card.Text><strong>Tipo:</strong> {asistencia.ASIS_chEntSal === 'E' ? 'Entrada': 'Salida'}</Card.Text>
                  <Card.Text><strong>Descripción:</strong> {asistencia.DesAsistencia || "--"}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}

      </Modal.Body>
      <Modal.Footer className="justify-content-center border-top-0">
        <Button variant="secondary" onClick={onClose}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DetalleControl;