import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import API_PERSONALSEGURIDAD from "../../../componentes/config/apisPersonalS_Variables";
import Select from "react-select";
import ModalInfoVisible from "./AsistenciaBitacora/ModalInfoVisible";

// Función que Trae la fecha actual y la transforma en formato ddmmaaaa
const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function AsitenciasBitacora({ showModal, onClose }) {
  // Declaración de constantes y sus estados
  const [asistencias, setAsistencias] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [tipofiltro, setTipoFiltro] = useState("bpordni");
  const [loadingFiltro, setLoadingFiltro] = useState(false);
  const [mes, setMes] = useState("06");
  // Estado de fecha , trae la fecha de hoy como dato inicial y lo transforma con la función formatDate
  const [usuarios, setUsuarios] = useState([]);
  const [documento, setDocumento] = useState("");
  const [fecha, setFecha] = useState(formatDate(new Date()));
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  const [modalImagen, setModalImagen] = useState(false);
  const [selectedAsistencia, setSelectedAsistencia] = useState(null);
  const [modalInfoVisible, setModalInfoVisible] = useState(false);
  const ImagenError =
    "https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.webp";

  // Función que Cambia la fecha en el input de buscar por fecha
  const handleChangefecha = (event) => {
    const date = event.target.value;
    setFecha(date);
  };

  const handleRowClick = (asistencia) => {
    setSelectedAsistencia(asistencia);
    setModalInfoVisible(true);
  };

  const handlePreview = async (imageId) => {
    try {
      const response = await axios.get(
        API_PERSONALSEGURIDAD["ImagenAsistencia"](imageId)
      );
      // Asume que response.data.images[0] es la cadena base64
      const base64 = response.data.images[0];

      // Crear una URL de datos a partir de la cadena base64
      const imageUrl = `data:image/jpeg;base64,${base64}`;

      // Establecer la imagen seleccionada y mostrar el modal
      setImagenSeleccionada(imageUrl);
      setModalImagen(true);
    } catch (error) {
      setImagenSeleccionada(ImagenError);
      setModalImagen(true);
    }
  };

  // Función que regresa la fecha al dia anterión (para botón de buscar por fecha)
  const handlePrevDay = () => {
    try {
      const day = Number(fecha.substring(0, 2));
      const month = Number(fecha.substring(3, 5)) - 1;
      const year = Number(fecha.substring(6, 10));
      const currentDate = new Date(year, month, day);
      currentDate.setDate(currentDate.getDate() - 1);
      const formattedDate = currentDate.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      setFecha(formattedDate);
    } catch (error) {
      console.error("Error en handlePrevDay:", error);
    }
  };

  // Función que regresa la fecha al dia siguiente (para botón de buscar por fecha)
  const handleNextDay = () => {
    try {
      const day = Number(fecha.substring(0, 2));
      const month = Number(fecha.substring(3, 5)) - 1;
      const year = Number(fecha.substring(6, 10));
      const currentDate = new Date(year, month, day);
      currentDate.setDate(currentDate.getDate() + 1);
      const formattedDate = currentDate.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      setFecha(formattedDate);
    } catch (error) {
      console.error("Error en handleNextDay:", error);
    }
  };

  // Función para cambiar el tipo de filtro (HOY , FECHA , MES)
  const handleTipoFiltroChange = (tipo) => {
    setTipoFiltro(tipo);
    setLoadingFiltro(true);
  };

  // Función para actualizar el estado de mes (cambiarle el mes)
  const handleChangemes = (event) => {
    const selectedValue = event.target.value;
    setMes(selectedValue);
  };

  // Ejecuta la funcion para obtener los datos del usuario por su dni y actualiza por cada digito de dni que ingrese
  useEffect(() => {
    const obtenerDatosporDNI = async () => {
      setLoadingFiltro(true);
      try {
        const apiUrl = `https://api01.cloudlab.net.pe/pi0303bl_asistencia_q/?vtipobusqueda=${tipofiltro}&vvalorbuscado1=${
          tipofiltro === "bpordia"
            ? fecha.split("/").join("")
            : tipofiltro === "bpormes"
            ? mes
            : documento
        }`;
        const respuesta = await axios.get(apiUrl);
        console.log(respuesta);

        const datos = respuesta.data.data || [];
        setAsistencias(datos.map((item) => item.data));
        setLoadingFiltro(false);
      } catch (error) {
        setShowToast(true);
        setErrorMessage("Error al obtener los datos (pi0302bh1_)");
      }
      setLoadingFiltro(false);
    };
    obtenerDatosporDNI();
  }, [tipofiltro, mes, fecha, documento]);

  const gettiporegistro = (tiporegistro) => {
    // Switch para darle el nombre de manera visual al tregistro seleccionado
    switch (tiporegistro) {
      case "Asis" || "asis":
        return "Asistencia";
      case "Inasis":
        return "Inasistencia";
      case "Acti":
        return "Actividad";
      case "Tar":
        return "Tardanza";
      case "Rad":
        return "Radio";
      case "Mot":
        return "Moto";
      case "Camio":
        return "Camioneta";
      case "Bic":
        return "Bicicleta";
      case "SSD":
        return "Sueldo Sin Deposito";
      case "SUP":
        return "Sueldo Pagado";
      case "PF":
        return "Puesto Tactico";
      case "PT":
        return "Puesto Tactico";
      case "Onom":
        return "Onomástico";
      case "PermP":
        return "Permiso Personal";
      case "PermS":
        return "Permiso por Salud";
      case "Vac":
        return "Vacaciones";
      case "De":
        return "Descanso";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (!loadingFiltro) {
      if ($.fn.dataTable.isDataTable("#tablaregistrosdni")) {
        $("#tablaregistrosdni").DataTable().destroy();
      }
      $("#tablaregistrosdni").DataTable({
        destroy: true,
        language: {
          sProcessing: "Procesando...",
          sLengthMenu: "Mostrar _MENU_ registros",
          sZeroRecords: "No se encontraron resultados",
          sEmptyTable: "Ningún dato disponible en esta tabla",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
          sSearch: "Buscar:",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
        initComplete: function () {
          this.api()
            .columns()
            .every(function () {
              var column = this;
              $("input", this.header()).on("keyup change", function () {
                if (column.search() !== this.value) {
                  column.search(this.value).draw();
                }
              });
            });
        },
      });
    }
  }, [loadingFiltro, asistencias, showModal]);

  // Obtiene la fecha y le da formato dd/mm/aaaa para mostrarse de manera visual
  const formatofecha = (dateStr) => {
    const day = dateStr.substring(0, 2);
    const month = dateStr.substring(2, 4);
    const year = dateStr.substring(4, 8);
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(
          API_PERSONALSEGURIDAD["listarPersonal"]
        );
        setUsuarios(response.data);
      } catch (error) {
        setErrorMessage(
          `Error en el servidor para obtener los usuarios (pi120_M_MA)`
        );
        setShowToast(true);
      }
    };
    fetchUsuarios();
  }, []);

  const customStyles = {
    content: {
      Width: "100%",
      Height: "100%",
      margin: "auto",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
    },
  };

  const modalImageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
  };

  return (
    <Modal
      show={showModal}
      onHide={onClose}
      dialogClassName="modal-custom"
      centered
      className="funcionmodal"
    >
      <Modal.Header closeButton className="bg-primary text-center">
        <Modal.Title
          style={{
            color: "#fff",
            width: "100%",
            fontSize: "15px",
            margin: "2px",
          }}
        >
          Bitácora de Control
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tabladedatos" style={{ padding: "5px" }}>
        {showToast && (
          <div
            className="position-fixed bottom-0 end-0 p-3"
            style={{ zIndex: 11 }}
          >
            <div
              className="toast show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-header bg-primary text-white">
                <strong className="me-auto">Mensaje del Sistema</strong>
                <small>Justo Ahora</small>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowToast(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="toast-body">{errorMessage}</div>
            </div>
          </div>
        )}
        <div
          className="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
          style={{ padding: "10px", width: "100%" }}
        >
          <input
            type="radio"
            className="btn-check funcionmodal"
            name="options"
            id="option1"
            autoComplete="off"
            checked={tipofiltro === "bpordni"}
            onChange={() => handleTipoFiltroChange("bpordni")}
          />
          <label
            className={`btn ${
              tipofiltro === "bpordni" ? "btn-success" : "btn-secondary"
            } funcionmodal font-Responsive`}
            htmlFor="option1"
          >
            Filtro por Documento
          </label>
          <input
            type="radio"
            className="btn-check funcionmodal"
            name="options"
            id="option2"
            autoComplete="off"
            checked={tipofiltro === "bpordia"}
            onChange={() => handleTipoFiltroChange("bpordia")}
          />
          <label
            className={`btn ${
              tipofiltro === "bpordia" ? "btn-info" : "btn-secondary"
            } funcionmodal font-Responsive`}
            htmlFor="option2"
          >
            Filtrar por Fecha
          </label>
          <input
            type="radio"
            className="btn-check "
            name="options"
            id="option3"
            autoComplete="off"
            checked={tipofiltro === "bpormes"}
            onChange={() => handleTipoFiltroChange("bpormes")}
          />
          <label
            className={`btn ${
              tipofiltro === "bpormes" ? "btn-warning" : "btn-secondary"
            } funcionmodal font-Responsive`}
            htmlFor="option3"
          >
            Filtrar por Mes
          </label>
        </div>
        {loadingFiltro ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {tipofiltro === "bpormes" ? (
              <>
                <div
                  className="form-group funcionmodal"
                  style={{ padding: "5px" }}
                >
                  <label htmlFor="">Escoge un Mes:</label>
                  <select
                    className="form-select funcionmodal"
                    name="mes"
                    value={mes}
                    onChange={handleChangemes}
                  >
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </select>
                </div>
                <br />
              </>
            ) : tipofiltro === "bpordia" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                    }}
                    onClick={handlePrevDay}
                  >
                    <i class="fa-solid fa-circle-arrow-left"></i>
                  </button>
                  <input
                    className="form-control funcionmodal"
                    type="text"
                    value={fecha}
                    style={{
                      width: "60%",
                      margin: "0 20px",
                      border: "2px solid black",
                      textAlign: "center",
                    }}
                    onChange={handleChangefecha}
                  />
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                    }}
                    onClick={handleNextDay}
                  >
                    <i class="fa-solid fa-circle-arrow-right"></i>
                  </button>
                </div>
                <br />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "95%",
                    margin: "auto",
                    marginBottom: "5px",
                    padding: "5px",
                  }}
                >
                  <Select
                    className="w-100 SelectRecurso"
                    options={usuarios.map((sereno) => ({
                      value: sereno.SERE_P_chDNI,
                      label: `( ${sereno.SERE_P_chDNI} ) ${sereno.SERE_APELLNOM}`,
                    }))}
                    value={
                      usuarios.find(
                        (sereno) => sereno.SERE_P_chDNI === documento
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      setDocumento(selectedOption ? selectedOption.value : null)
                    }
                    isSearchable
                    placeholder="Seleccione el usuario"
                  />
                </div>
              </>
            )}
            <div
              className="buscadores"
              style={{ display: "flex", gap: "10px", padding: "0 15px" }}
            >
              <div className="input-group mb-3">
                <span className="input-group-text" id="hora-addon">
                  Hora
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Hora"
                  aria-label="Hora"
                  aria-describedby="hora-addon"
                  onChange={(e) => {
                    $("#tablaregistrosdni")
                      .DataTable()
                      .column(4)
                      .search(e.target.value)
                      .draw();
                  }}
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="turno-addon">
                  Cargo
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cargo"
                  aria-label="Turno"
                  aria-describedby="turno-addon"
                  onChange={(e) => {
                    $("#tablaregistrosdni")
                      .DataTable()
                      .column(2)
                      .search(e.target.value)
                      .draw();
                  }}
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="turno-addon">
                  Turno
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Turno"
                  aria-label="Turno"
                  aria-describedby="turno-addon"
                  onChange={(e) => {
                    $("#tablaregistrosdni")
                      .DataTable()
                      .column(5)
                      .search(e.target.value)
                      .draw();
                  }}
                />
              </div>
            </div>
            <div className="table-responsive overflow-auto text-center">
              <table id="tablaregistrosdni" className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center">Nombre y Apellidos</th>
                    <th className="text-center">Documento</th>
                    <th className="text-center small-col">Cargo</th>
                    <th className="text-center">Fecha Registro</th>
                    <th className="text-center">Hora - Hora Modificada</th>
                    <th className="text-center small-col">Turno</th>
                    <th className="text-center small-col">Tipo Registro</th>
                    <th className="text-center small-col">Tipo</th>
                    <th className="text-center">Foto</th>
                    <th className="text-center">Detalle</th>
                  </tr>
                </thead>
                <tbody>
                  {asistencias.map((asistencia, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: "12px" }}>
                        {asistencia.SERE_chNOM} {asistencia.SERE_chAPEPAT}{" "}
                        {asistencia.SERE_chAPEMAT}
                      </td>
                      <td>{asistencia.SERE_P_chDNI}</td>
                      <td>
                        {asistencia.AbrParm_cargo
                          ? asistencia.AbrParm_cargo
                          : "---"}
                      </td>
                      <td>{formatofecha(asistencia.asis_chfec)}</td>
                      <td>
                        {asistencia.HoraReg} - {asistencia.HoraRegM}
                      </td>
                      <td>
                        {asistencia.Marc_ch_Nomturn_asis
                          ? asistencia.Marc_ch_Nomturn_asis
                          : "--"}
                      </td>
                      <td>
                        {gettiporegistro(asistencia.TipoRegistroDetalle).charAt(
                          0
                        )}
                      </td>
                      <td>{asistencia.EntSal.charAt(0)}</td>
                      <td className="foto-col">
                        {asistencia.ASIS_P_inID ? (
                          <button
                            className="btn btn-success btn-sm"
                            style={{ fontSize: "10px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePreview(asistencia.ASIS_P_inID);
                            }}
                          >
                            Ver Foto
                          </button>
                        ) : (
                          <span className="w-100" style={{ fontSize: "10px" }}>
                            No tiene Foto
                          </span>
                        )}
                      </td>
                      <td>
                        <button
                          onClick={() => handleRowClick(asistencia)}
                          style={{ cursor: "pointer", fontSize: "10px" }}
                          className="btn btn-warning btn-sm m-1"
                        >
                          Ver Detalle
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onClose}>
          Cerrar
        </Button>
      </Modal.Footer>
      <ModalInfoVisible
        show={modalInfoVisible}
        onClose={() => setModalInfoVisible(false)}
        asistencia={selectedAsistencia}
      />
      {modalImagen && (
        <Modal
          show={modalImagen}
          onHide={() => setModalImagen(false)}
          style={customStyles}
          centered
        >
          <Modal.Header closeButton>
            <div>
              <img
                src={imagenSeleccionada}
                alt="Previsualización"
                style={modalImageStyle}
                onError={(e) => {
                  e.target.src =
                    "https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.webp";
                }}
              />
            </div>
          </Modal.Header>
        </Modal>
      )}
    </Modal>
  );
}

export default AsitenciasBitacora;
