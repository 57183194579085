import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import API_PERSONALSEGURIDAD from "../../../../componentes/config/apisPersonalS_Variables";

function ListadoAcciones({ show, onHide,setSelectedAccion }) {
  const [listadoAcciones, setListadoAcciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const obtenerDatos = async () => {
    try {
      setLoading(true);
      const respuesta = await axios.get(
        API_PERSONALSEGURIDAD["listadoacciones"]
      );
      setListadoAcciones(respuesta.data);
      console.log(respuesta.data);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    obtenerDatos();
  }, []);
  useEffect(() => {
    if (!loading) {
      // Destruir el DataTable existente antes de inicializar uno nuevo
      const table = $("#example3").DataTable();
      if ($.fn.DataTable.isDataTable(table)) {
        table.destroy();
      }
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          sProcessing: "Procesando...",
          sLengthMenu: "Mostrar _MENU_ registros",
          sZeroRecords: "No se encontraron resultados",
          sEmptyTable: "Ningún dato disponible en esta tabla",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
          sInfoPostFix: "",
          sSearch: "Buscar:",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando...",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending:
              ": Activar para ordenar la columna de manera ascendente",
            sSortDescending:
              ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copy: "Copiar",
            colvis: "Visibilidad",
          },
        },
      });

      // Inicializar el DataTable
      $("#example3").DataTable();
    }
  }, [loading, show]);
  const handleSeleccionarAccion = (accion) => {
    setSelectedAccion({
      value: accion.ACCI_P_inID,
      label: `${accion.ACCI_chCOD} - ${accion.ACCI_chDES}`,
    });
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-xl" centered>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        ) : (
          <div className="card col-sm-12" style={{ maxWidth: "100%" }}>
            <div className="card-body">
              <div className="table-responsive">
                <table
                  id="example3"
                  className="table table-striped text-center"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Acción</th>
                      <th>Seleccionar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listadoAcciones !== null &&
                      listadoAcciones.map((index) => (
                        <tr key={index.ACCI_P_inID}>
                          <td>{index.ACCI_chCOD}</td>
                          <td>{index.ACCI_chDES}</td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-success btn-sm "
                              title="Seleccionar Acción"
                              style={{fontSize:'10px',width:'100%'}}
                              onClick={() => handleSeleccionarAccion(index)}
                            >
                              Seleccionar
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button variant="danger" onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ListadoAcciones;
