import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import serenosIcon from '../../../assets/markeractivo.gif';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import API_ASISTENCIA from '../../../componentes/config/apisAsistencia_Variables';
import credenciales from '../../../componentes/config/credenciales';

const customIconCamaras = new L.Icon({
    iconUrl: serenosIcon,  // Usamos el archivo serenos.svg
    iconSize: [32, 32],     // Tamaño del ícono
    iconAnchor: [16, 32],   // El punto de anclaje del ícono
    popupAnchor: [0, -32],  // Donde se posiciona el Popup
});

const ModalCoordenadas = ({ showModal, handleClose, selectedDNI }) => {
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Fecha actual por defecto
    const [reportData, setReportData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [transportType, setTransportType] = useState('pie'); // Estado para el tipo de transporte

    const fetchReportData2 = async () => {
        if (!selectedDNI) {
            return; // Si no se ha seleccionado un DNI, no se hace la solicitud
        }

        setIsLoading(true);
        setReportData(null);

        const username = credenciales.usuario;
        const password = credenciales.contrasena;
        const encodedCredentials = btoa(`${username}:${password}`);

        try {
            const url = API_ASISTENCIA.RondasPorDni(selectedDate, selectedDNI);  // La URL con los parámetros
            console.log('URL generada:', url); // Para depuración

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${encodedCredentials}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            console.log('Datos recibidos:', data);

            if (response.ok) {
                if (data.message && data.message.includes('No se encontraron rondas')) {
                    Swal.fire({
                        title: 'Sin rondas',
                        text: data.message,
                        icon: 'warning',
                    });
                } else if (data.coordenadas && data.coordenadas.length > 0) {
                    setReportData(data);
                } else {
                    Swal.fire({
                        title: 'Sin rondas',
                        text: 'No hay rondas disponibles para los parámetros seleccionados.',
                        icon: 'warning',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error al obtener datos',
                    text: data.message || 'Error desconocido.',
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
            Swal.fire({
                title: 'Error',
                text: `No se pudo obtener los datos. Detalles: ${error.message}`,
                icon: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReportData2();  // Llamar a la función al cargar el componente
    }, [selectedDate, selectedDNI]);

    // Para dibujar la ruta de las coordenadas
    const coordenadasRuta = reportData && reportData.coordenadas
        ? reportData.coordenadas.map(coordenada => ({
            lat: coordenada.asis_in_lat,
            lng: coordenada.asis_in_lon,
        }))
        : [];

    // Función para seleccionar el estilo de la ruta basado en el tipo de transporte
    const getRouteStyle = (transportType) => {
        switch (transportType) {
            case 'auto':
                return { color: 'red', weight: 6, dashArray: '5, 5' };  // Línea roja para auto, estilo discontinuo
            case 'moto':
                return { color: 'green', weight: 4, dashArray: '10, 5' };  // Línea verde para moto
            case 'pie':
            default:
                return { color: 'blue', weight: 3 };  // Línea azul para a pie
        }
    };

    return (
        <Modal show={showModal} onHide={handleClose} size="xl" centered>
            <Modal.Header closeButton className="bg-danger text-white">
                <Modal.Title>DETALLES DEL RECORRIDO DE {selectedDNI}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mx-2 my-2">
                    {/* Agregar inputs para nombre completo y función */}
                    {reportData && reportData.nombre_completo && reportData.funcion && (
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12" style={{ maxWidth: "100%" }}>
                            <div className="row">
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xs-12">
                                    <input
                                        type="date"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                        className="form-control form-control-sm mb-3"
                                        style={{ maxWidth: '100%' }}
                                    />
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xs-12">
                                    <input
                                        type="text"
                                        value={selectedDNI}
                                        className="form-control form-control-sm mb-3"
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">
                                    <input
                                        type="text"
                                        value={reportData.nombre_completo}
                                        className="form-control form-control-sm mb-3"
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">
                                    <input
                                        type="text"
                                        value={reportData.funcion}
                                        className="form-control form-control-sm mb-3"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Selector para tipo de transporte */}
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 mb-3">
                        <label>Selecciona el tipo de transporte</label>
                        <select
                            value={transportType}
                            onChange={(e) => setTransportType(e.target.value)}
                            className="form-control"
                        >
                            <option value="pie">A pie</option>
                            <option value="moto">En moto</option>
                            <option value="auto">En auto</option>
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12" style={{ maxWidth: "100%" }}>
                        <MapContainer
                            center={{ lat: -12.068773, lng: -77.02908 }}
                            zoom={16}
                            style={{ width: "100%", height: "85vh" }}>

                            <TileLayer
                                attribution={`MUNICIPALIDAD DISTRITAL DE LA VICTORIA VERSION 1.0 ${new Date().getFullYear()}`}
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            {reportData && reportData.coordenadas.map((coordenada, index) => (
                                <Marker
                                    key={index}
                                    position={{
                                        lat: coordenada.asis_in_lat,
                                        lng: coordenada.asis_in_lon
                                    }}
                                    icon={customIconCamaras}
                                >
                                    <Popup>
                                        <strong>Fecha:</strong> {coordenada.ASIS_dtFECHOR}
                                    </Popup>
                                </Marker>
                            ))}

                            {/* Dibujar la ruta con Polyline según el tipo de transporte */}
                            {coordenadasRuta.length > 1 && (
                                <Polyline
                                    positions={coordenadasRuta}
                                    {...getRouteStyle(transportType)}  // Aplica el estilo basado en el tipo de transporte
                                />
                            )}
                        </MapContainer>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCoordenadas;
