import { useEffect, useState, useRef } from "react";
import { Button, Modal, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

function ModalInfoVisible({ show, onClose, asistencia }) {
  const [imageSrc, setImageSrc] = useState(null);
  const defaultImage = "https://via.placeholder.com/150";
  const printRef = useRef(null);

  useEffect(() => {
    if (asistencia && asistencia.ASIS_P_inID) {
      const fetchImage = async () => {
        try {
          const response = await axios.get(
            `https://api01.cloudlab.net.pe/pi14filjson_iddownload_fil_asissql/?vopc_todos=bporid&vvalor_buscado=${asistencia.ASIS_P_inID}`
          );
          if (response.status === 200 && response.data.images) {
            const base64Image = response.data.images[0];
            setImageSrc(`data:image/jpeg;base64,${base64Image}`);
          } else {
            setImageSrc(defaultImage);
          }
        } catch (error) {
          console.error("Error al obtener la imagen:", error);
          setImageSrc(defaultImage);
        }
      };
      fetchImage();
    } else {
      setImageSrc(defaultImage);
    }
  }, [asistencia]);

  const gettiporegistro = (tiporegistro) => {
    switch (tiporegistro) {
      case "Asis":
        return "Asistencia";
      case "Inasis":
        return "Inasistencia";
      case "Acti":
        return "Actividad";
      case "Tar":
        return "Tardanza";
      case "Rad":
        return "Radio";
      case "Mot":
        return "Moto";
      case "Camio":
        return "Camioneta";
      case "Bic":
        return "Bicicleta";
      case "SSD":
        return "Sueldo Sin Deposito";
      case "SUP":
        return "Sueldo Pagado";
      case "PF":
        return "Puesto Fijo";
      case "PT":
        return "Puesto Táctico";
      case "Onom":
        return "Onomástico";
      case "PermP":
        return "Permiso Personal";
      case "PermS":
        return "Permiso por Salud";
      case "Vac":
        return "Vacaciones";
      case "De":
        return "Descanso";
      default:
        return "";
    }
  };

  const formatofecha = (dateStr) => {
    const day = dateStr.substring(0, 2);
    const month = dateStr.substring(2, 4);
    const year = dateStr.substring(4, 8);
    return `${day}/${month}/${year}`;
  };

  const handlePrint = () => {
    if (!printRef.current) {
      console.error("printRef.current no está definido");
      return;
    }

    const printContent = printRef.current.cloneNode(true);
    const printWindow = window.open("", "", "width=800,height=600");

    printWindow.document.write(`
      <html>
        <head>
          <title>Imprimir Detalles</title>
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            .detalleControl img {
              max-width: 100%;
              height: auto;
            }
            .detalleControl {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .FilaFotos-detalleControl {
              display: flex;
              gap: 20px;
              justify-content: space-between;
            }
            .detalleControl .card {
              margin-top: 20px;
              width: 100%;
            }
            .card-title, .card-text {
              font-size: 13px;
            }
            @media print {
              body {
                -webkit-print-color-adjust: exact;
              }
              .FilaFotos-detalleControl img {
                max-width: 300px;
                height: auto;
                display: inline-block;
                border: 3px solid #ddd;
              }
            }
          </style>
        </head>
        <body>${printContent.outerHTML}</body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();

    printWindow.onload = () => {
      printWindow.print();
    };
  };

  return (
    <Modal show={show} onHide={onClose} centered size="lg" className='funcionmodal'>
      <Modal.Header closeButton className="bg-warning text-black shadow-sm">
        <Modal.Title className="fw-bold" style={{fontSize:'13px'}}>Información de Bitácora</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4" ref={printRef}>
        {asistencia && (
          <div className="detalleControl">
            <div className="FilaFotos-detalleControl">
              <img
                src={`https://api01.cloudlab.net.pe/pi20b100-download-archisql/${asistencia.SERE_P_chDNI}/fotoperfil/1/`}
                alt="Foto del usuario"
                style={{
                  maxWidth: "250px",
                  height: "auto",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "3px solid #ddd",
                  objectFit: "cover",
                  marginBottom: "20px",
                }}
                onError={(e) => {
                  e.target.src = defaultImage;
                }}
              />
              <img
                src={imageSrc || defaultImage}
                alt="Foto del usuario"
                style={{
                  maxWidth: "250px",
                  height: "auto",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "3px solid #ddd",
                  objectFit: "cover",
                  marginBottom: "20px",
                }}
                onError={(e) => {
                  e.target.src = defaultImage;
                }}
              />
            </div>
            <div className="FilaFotos-detalleControl">
              <Card
                className="shadow-sm border-0"
                style={{ borderRadius: "10px", marginTop: "20px" }}
              >
                <Card.Body>
                  <Card.Title className="fw-semibold text-primary" style={{fontSize:'13px'}}>
                    Datos del Personal
                  </Card.Title>
                  <Card.Text style={{fontSize:'13px'}}>
                    <strong>Datos:</strong> {asistencia.DatosPersonal}
                  </Card.Text>
                  <Card.Text style={{fontSize:'13px'}}>
                    <strong>Documento:</strong> {asistencia.SERE_P_chDNI}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="shadow-sm border-0 mb-3"
                style={{ borderRadius: "10px", textAlign: "left" }}
              >
                <Card.Body>
                  <Card.Title className="fw-semibold text-primary" style={{fontSize:'13px'}}>
                    Detalles del Registro
                  </Card.Title>
                  <Card.Text style={{fontSize:'13px'}}>
                    <strong>Fecha y Hora:</strong>{" "}
                    {formatofecha(asistencia.asis_chfec)} - {asistencia.HoraReg}
                  </Card.Text>
                  <Card.Text style={{fontSize:'13px'}}>
                    <strong>Hora Modificada:</strong> {asistencia.HoraRegM}
                  </Card.Text>
                  <Card.Text style={{fontSize:'13px'}}>
                    <strong>Tipo Registro:</strong>{" "}
                    {gettiporegistro(asistencia.TipoRegistroDetalle)} - {asistencia.EntSal}
                  </Card.Text>
                  <Card.Text style={{fontSize:'13px'}}>
                    <strong>Turno:</strong>{" "}
                    {asistencia.Marc_ch_Nomturn_asis ? asistencia.Marc_ch_Nomturn_asis : '--' }
                  </Card.Text>
                  <Card.Text style={{fontSize:'13px'}}>
                    <strong>Descripción:</strong>{" "}
                    {asistencia.DesAsistencia || "--"}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-top-0">
        <Button variant="primary" onClick={handlePrint}>Imprimir</Button>
        <Button variant="secondary" onClick={onClose}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalInfoVisible;