import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Slider from "react-slick";
import Swal from "sweetalert2";
import { FaFilePdf } from 'react-icons/fa';
import API_PERSONALSEGURIDAD from "../../componentes/config/apisPersonalS_Variables";

function FichaPersonal({ show, onHide, selectedFichaPersona }) {
  const [datosPersonales, setDatosPersonales] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(() => {
    const fetchDatos = async () => {
      setLoadingModal(true)
      try {
        const response = await axios.get(
          API_PERSONALSEGURIDAD['serenosPorDNI'](selectedFichaPersona)
        );
        setDatosPersonales(response.data);
        setLoadingModal(false)
        console.log(response.data);
      } catch (error) {
        console.error("Error al obtener las imágenes:", error);
      }
    };

    fetchDatos();
  }, [selectedFichaPersona]);
  const fetchGeneratePDF = async(dni) =>{
    setLoadingPDF(true);
    try {
      const response = await axios.get(
        API_PERSONALSEGURIDAD['generarPDF'](dni),
        { responseType: 'arraybuffer' } // Indicar que se espera un arraybuffer en la respuesta
      );

      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `REPORTE_PERSONAL_MLV_${dni}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        console.log("Ficha Descargada Correctamente");
        Swal.fire({
          icon: "success",
          title: "Descarga Exitosa",
          text: "Se descargó el archivo correctamente",
        }).then()
      } else {
        Swal.fire({
          icon: "error",
          title: "Error en la Descarga",
          text: "No se pudo descargar la ficha personal (pi801_repo)",
        }).then()      
      } 
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error en la Descarga",
        text: "No se pudo Conectar con el servidor (pi801_repo)",
      }).then()       
    }
    setLoadingPDF(false); // Desactivar spinner de carga

  }

  const {
    SERE_chNOM,
    SERE_chAPEPAT,
    SERE_chAPEMAT,
    SERE_chTELEF,
    SERE_chSEXO,
    SERE_chEMAIL,
    SERE_P_chDNI,
    SERE_chRUC,
    SERE_chRol,
    SERE_chDIR,
    SERE_chLICCON,
    SERE_chTALPOL,
    AFIL_P_inID,
    SERE_chTALPAN,
    SERE_chTALCAL,
    SERE_reTALLA,
    SERE_rePESO,
    SERE_chTIPSAN,
    SERE_foSUE,
    SERE_chFECALT,
    SERE_chCONTRATO,
    SERE_chNCCI
  } = datosPersonales;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      {loadingModal ? (
            <Modal.Body className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </Modal.Body>
      ):(
    <Modal.Body>
        <div className="row">
          <div className="col-xs-5 col-sm-5 col-md-5 text-center">
            <div className="form-group">
              <label className="text-center">
                {" "}
                <strong>Foto de Perfil</strong>{" "}
              </label>
              {datosPersonales ? (
                <p>
                  <img
                    src={API_PERSONALSEGURIDAD['mostrarfotodeusuario'](selectedFichaPersona)}
                    alt="Foto de Perfil"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "350px",
                      margin: "25px",
                      borderRadius: "10px",
                    }}
                    onError={(e) => {
                      e.target.src = 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.webp';
                    }}
                  />
                </p>
              ) : (
                <div>No registra</div>
              )}
            </div>
          </div>
          <div className="col-xs-7 col-sm-7 col-md-7 text-left">
            <Slider {...settings} style={{ margin: "20px" }}>
              <div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group ">
                    <label htmlFor="SERE_chNOM">
                      <strong>Nombres y Apellidos</strong>
                    </label>
                    <p>
                      {SERE_chNOM} {SERE_chAPEPAT} {SERE_chAPEMAT}
                    </p>
                  </div>
                </div>
                {/*        <div className="row" style={{"margin-top":'10px '}}>
          <div className="col-xs-6 col-sm-6 col-md-6">
            <div className="form-group">
                <label htmlFor="">Apellido Paterno:</label>
                
            </div> 
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6">
            <div className="form-group">
                <label htmlFor="">Apellido Materno:</label>
                
            </div> 
          </div>
        </div>*/}

                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        <strong>Teléfono</strong>
                      </label>
                      <p>{SERE_chTELEF ? SERE_chTELEF : "No Registra"}</p>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        <strong>Sexo</strong>
                      </label>
                      <p>{SERE_chSEXO ? SERE_chSEXO : "No Registra"}</p>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ "margin-top": "10px" }}>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        <strong>Email: </strong>
                      </label>
                      {typeof SERE_chEMAIL === "string" ? (
                        SERE_chEMAIL.length === 0 ||
                        SERE_chEMAIL.trim() === "" ? (
                          <p>No Registra</p>
                        ) : SERE_chEMAIL.length <= 30 ? (
                          <p>{SERE_chEMAIL}</p>
                        ) : (
                          <p style={{ fontSize: "12px" }}>{SERE_chEMAIL}</p>
                        )
                      ) : (
                        <p>No Registra</p>
                      )}
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        <strong>Cargo: </strong>
                      </label>
                      <p>{SERE_chRol}</p>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        <strong>Documento: </strong>
                      </label>
                      <p>{SERE_P_chDNI}</p>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        <strong>Ruc: </strong>
                      </label>
                      <p>{SERE_chRUC ? SERE_chRUC : "No Registra"}</p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group ">
                    <label htmlFor="SERE_chNOM">
                      <strong>Dirección</strong>
                    </label>
                    <p>{SERE_chDIR ? SERE_chDIR : "No Registra"}</p>
                  </div>
                </div>
                {/*<div className="col-xs-12 col-sm-12 col-md-12" style={{"margin-top":'10px '}} >
          <div className="form-group">
            <label htmlFor="">Dirección:</label>
            <p>{SERE_chDIR}</p>

          </div>
        </div>*/}
              </div>
              <div style={{display:'flex' ,flexDirection:'column',justifyContent:'center',height:'100%'}}>
                {/*        <div className="row" style={{"margin-top":'10px '}}>
          <div className="col-xs-6 col-sm-6 col-md-6">
            <div className="form-group">
                <label htmlFor="">Apellido Paterno:</label>
                
            </div> 
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6">
            <div className="form-group">
                <label htmlFor="">Apellido Materno:</label>
                
            </div> 
          </div>
        </div>*/}

                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="" style={{ fontSize: "12px" }}>
                        <strong>Talla Polo: </strong>
                      </label>
                      <p>{SERE_chTALPOL ? SERE_chTALPOL : "No Registra"}</p>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="" style={{ fontSize: "12px" }}>
                        <strong>Talla Pantalón: </strong>
                      </label>
                      <p>{SERE_chTALPAN ? SERE_chTALPAN : "No Registra"}</p>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="" style={{ fontSize: "12px" }}>
                        <strong>Talla Calzado: </strong>
                      </label>
                      <p>{SERE_chTALCAL ? SERE_chTALCAL : "No Registra"}</p>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="" style={{ fontSize: "12px" }}>
                        <strong>Talla: </strong>
                      </label>
                      <p>{SERE_reTALLA ? `${SERE_reTALLA}m` : "No Registra"}</p>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="" style={{ fontSize: "12px" }}>
                        <strong>Peso: </strong>
                      </label>
                      <p>{SERE_rePESO ? `${SERE_rePESO}Kg` : "No Registra"}</p>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="" style={{ fontSize: "12px" }}>
                        <strong>Tipo de Sangre: </strong>
                      </label>
                      <p>{SERE_chTIPSAN ? SERE_chTIPSAN : "No Registra"}</p>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="" style={{ fontSize: "12px" }}>
                        <strong>Tipo de contrato: </strong>
                      </label>
                      <p>{SERE_chCONTRATO ? SERE_chCONTRATO : "No Registra"}</p>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="" style={{ fontSize: "12px" }}>
                        <strong>Fecha de Ingreso: </strong>
                      </label>
                      <p>{SERE_chFECALT ? SERE_chFECALT : "No Registra"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </Modal.Body>
      )}
      <Modal.Footer>
      <Button variant="warning" onClick={() => fetchGeneratePDF(SERE_P_chDNI)}>
          {loadingPDF ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '5px' }}
              />
              Descargando...
            </>
          ) : (
            <>
              <FaFilePdf style={{ color: '#c60606' }} /> Descargar Ficha Personal
            </>
          )}
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FichaPersonal;
