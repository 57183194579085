import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import Swal from "sweetalert2";
import "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net";
import "datatables.net-bs5";
import API_PERSONALSEGURIDAD from "../../componentes/config/apisPersonalS_Variables";
function ModalImagenes({ show, onHideMI, selectedImagen }) {
  const [images, setImages] = useState([]);
  const [clasearchivo, setClaseArchivo] = useState("0");
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [tipoarchivo, setTipoArchivo] = useState("c");
  const [archivos, setArchivos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingViewFile, setLoadingViewFile] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setClaseArchivo(selectedValue);
  };

  const handleTipoArchivoChange = (tipo) => {
    setLoading(true);
    setTimeout(() => {
      setTipoArchivo(tipo);
      setLoading(false);
    }, 100);
  };
  useEffect(() => {
    setLoading(true);

    const fetchImages = async () => {
      try {
        const response = await axios.get(
          `https://api01.cloudlab.net.pe/pi20${tipoarchivo}_m_seren${
            tipoarchivo === "c" ? "o" : ""
          }list_${
            tipoarchivo === "c" ? "foto" : "files"
          }/${selectedImagen}/${clasearchivo}?skip=${skip}&limit=${limit}`
        );
        setImages(response.data);
        setArchivos(response.data);
      } catch (error) {
        console.error("Error al obtener las imágenes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [selectedImagen, clasearchivo, tipoarchivo, skip, limit]);

  const handleDownload = async (imageId, imageName) => {
    try {
      const response = await axios.get(
        API_PERSONALSEGURIDAD['descargarimagen'](imageId),
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", imageName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar la imagen:", error);
      Swal.fire({
        icon: "error",
        title: "Error al descargar imagen",
        text: "No se pudo descargar la imagen (pi20b-down)",
      });
    }
  };
  const handleDownloadArchivo = async (archivoId, archivoName) => {
    try {
      const response = await axios.get(
        API_PERSONALSEGURIDAD['descargararchivo'](archivoId),
        {
          responseType: "blob",
        }
      );

      // Crear URL del archivo
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un elemento de enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", archivoName);

      // Agregar el enlace al DOM y hacer clic en él para iniciar la descarga
      document.body.appendChild(link);
      link.click();

      // Eliminar el enlace del DOM después de la descarga
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
      Swal.fire({
        icon: "error",
        title: "Error al descargar el archivo",
        text: "No se pudo descargar el archivo (pi20b-down)",
      });
    }
  };
  const handlePreviewArchivo = async (archivoId) => {
    setLoadingViewFile(true);
    try {
      const response = await axios.get(
        API_PERSONALSEGURIDAD['descargararchivo'](archivoId),
        {
          responseType: "arraybuffer", // Usamos 'arraybuffer' para la respuesta
        }
      );

      // Crear una URL de objeto para el arraybuffer
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setLoadingViewFile(false);

      // Abrir el PDF en un nuevo tab o ventana
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("Error al previsualizar el archivo:", error);
      Swal.fire({
        icon: "error",
        title: "Error al previsualizar el archivo",
        text: "No se pudo previsualizar el archivo (pi20b-down)",
      }).then(() => {
        setLoadingViewFile(true);
      });
    }
  };
  const handlePreview = (imageId) => {
    try {
      const imageUrl = API_PERSONALSEGURIDAD['descargarimagen'](imageId);
      console.log("URL de previsualización:", imageUrl);
      setImagenSeleccionada(imageUrl);
      console.log("Setting modal to visible");
      setModalVisible(true);
      console.log("Modal should be visible now");
    } catch (error) {
      console.error("Error al previsualizar la imagen:", error);
      Swal.fire({
        icon: "error",
        title: "Error al previsualizar imagen",
        text: "No se pudo previsualizar la imagen (pi20b-down)",
      });
    }
  };
  const customStyles = {
    content: {
      Width: "100%",
      Height: "100%",
      margin: "auto",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
    },
  };
  const modalImageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
  };

  return (
    <Modal show={show} onHide={onHideMI} dialogClassName="modal-lg" centered>
      <Modal.Header closeButton style={{ backgroundColor: "#0085e0" }}>
        <Modal.Title className="font-Responsive" style={{ color: "#fff", textAlign: "center" }}>
          Visualizar Archivos Registrados
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="btn-group RadioMI"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check font-Responsive"
            name="options"
            id="option1"
            autoComplete="off"
            checked={tipoarchivo === "c"}
            onChange={() => handleTipoArchivoChange("c")}
            style={{ width: "150px" }}
          />
          <label className="btn btn-secondary" htmlFor="option1">
            Ver Imagenes
          </label>

          <input
            type="radio"
            className="btn-check font-Responsive"
            name="options"
            id="option2"
            autoComplete="off"
            checked={tipoarchivo === "d"}
            onChange={() => handleTipoArchivoChange("d")}
            style={{ width: "350px" }}
          />
          <label className="btn btn-secondary" htmlFor="option2">
            Ver Archivos
          </label>
        </div>
        <hr />
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {tipoarchivo === "c" ? (
              <>
                <div className="form-group font-Responsive">
                  <label htmlFor="" className="font-Responsive">Tipo de Imagen:</label>
                  <select
                    className="form-select font-Responsive"
                    name="clasearchivo"
                    value={clasearchivo}
                    onChange={handleChange}
                  >
                    <option value="0">Todos</option>
                    <option value="fotoperfil">Foto de Perfil</option>
                    <option value="incidencia">Incidencia</option>
                  </select>
                </div>
                <div className="table-responsive-xl">
                  <table
                    id="example"
                    className="table table-striped "
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Acciones</th>
                        <th>Documento</th>
                        <th>Nombre de la Imagen</th>
                        <th>Tipo de la Imagen</th>
                        <th>ID Imagen</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {images !== null &&
                        images.map((index) => (
                          <tr key={index}>
                            <td className="botonestabla" style={{ minWidth: "100px" }}>
                              <button
                                type="button"
                                class="btn btn-success btn-sm  m-1 "
                                onClick={() =>
                                  handleDownload(
                                    index.archifoto_in_idpk,
                                    index.archifoto_ch_nomimg1
                                  )
                                }
                              >
                                <i class="fa-solid fa-download"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-warning btn-sm m-1"
                                onClick={() =>
                                  handlePreview(index.archifoto_in_idpk)
                                }
                              >
                                <i class="fa-solid fa-eye"></i>
                              </button>
                            </td>
                            <td>{index.archifoto_ch_codenla}</td>
                            <td style={{ maxWidth: "300px" }}>
                              {index.archifoto_ch_nomimg1}
                            </td>
                            <td>
                              {index.archifoto_ch_tipoimg1 === null
                                ? "Otros"
                                : index.archifoto_ch_tipoimg1}
                            </td>
                            <td>{index.archifoto_in_idpk}</td>
                            <td>
                              {index.archifoto_in_act === 1
                                ? "Activo"
                                : "Inactivo"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div>
                  {modalVisible && (
                    <Modal
                      show={modalVisible}
                      onHide={() => setModalVisible(false)}
                      style={customStyles}
                      size={"sm"}
                      centered
                    >
                      <div>
                        <img
                          src={imagenSeleccionada}
                          alt="Previsualización"
                          style={modalImageStyle}
                          onError={(e) => {
                            e.target.src = 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.webp';
                          }}
                        />
                      </div>
                    </Modal>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="form-group">
                  <label htmlFor="" className="font-Responsive">Tipo de Archivo:</label>
                  <select
                    className="form-select font-Responsive"
                    name="clasearchivo"
                    value={clasearchivo}
                    onChange={handleChange}
                  >
                    <option value="0">Todos</option>
                    <option value="curriculum">Curriculum</option>
                    <option value="incidencia">Incidencia</option>
                    <option value="faltas">Faltas</option>
                  </select>
                </div>
                <div className="table-responsive-xl">
                  <table
                    id="example"
                    className="table table-striped "
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Acciones</th>
                        <th>Documento</th>
                        <th>Nombre de la Imagen</th>
                        <th>Tipo de Archivo</th>
                        <th>ID Imagen</th>
                      </tr>
                    </thead>
                    <tbody>
                      {archivos !== null &&
                        archivos.map((index) => (
                          <tr key={index}>
                            <td className="botonestabla" style={{ minWidth: "100px" }}>
                              <button
                                type="button"
                                class="btn btn-success btn-sm  m-1 "
                                onClick={() =>
                                  handleDownloadArchivo(
                                    index.archifile_in_idpk,
                                    index.archifile_ch_nomfile1
                                  )
                                }
                              >
                                <i class="fa-solid fa-download"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-dark btn-sm  m-1 "
                                onClick={() =>
                                  handlePreviewArchivo(index.archifile_in_idpk)
                                }
                              >
                                {loadingViewFile ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <i class="fa-solid fa-eye"></i>
                                  </>
                                )}
                              </button>
                            </td>
                            <td>{index.archifile_ch_codenla}</td>
                            <td>{index.archifile_ch_nomfile1}</td>
                            <td>
                              {index.archifile_ch_tipofile1 === null
                                ? "Otros"
                                : index.archifile_ch_tipofile1}
                            </td>
                            <td>{index.archifile_in_idpk}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <div className="pagination font-Responsive">
          <Button
            variant="primary"
            onClick={() => setSkip(skip - limit)}
            disabled={skip === 0}
            className="font-Responsive"
          >
            Anterior
          </Button>
          <span className="mx-2">{`Página ${skip / limit + 1}`}</span>
          <Button
            variant="primary"
            onClick={() => setSkip(skip + limit)}
            disabled={images && archivos.length < limit}
            className="font-Responsive"
          >
            Siguiente
          </Button>
        </div>
        <Button variant="secondary" onClick={onHideMI} className="font-Responsive">
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ModalImagenes;
