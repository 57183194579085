import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";

const formatDate = (date) => {
  const day = String(date.getDate()+1).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function HistorialFaltas({ show, onHide }) {
  const [historialdefaltas, setHistorialDeFaltas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tipofiltro, setTipoFiltro] = useState("bpordia");
  const [fecha, setFecha] = useState(formatDate(new Date()));
  const [mes, setMes] = useState("06");

  // Función para cambiar el tipo de filtro (Día o Mes)
  const handleTipoFiltroChange = (e) => {
    setTipoFiltro(e.target.value);
  };

  const obtenerDatos = async () => {
    try {
      setLoading(true);
      const apiUrl = `https://api01.cloudlab.net.pe/pi20i_histoper_list_q/?vtipobusqueda=${tipofiltro}&vvalorbuscado1=${
        tipofiltro === "bpordia"
          ? fecha.split("/").join("")
          : mes
      }`;
      const respuesta = await axios.get(apiUrl);
      setHistorialDeFaltas(respuesta.data.data);
      console.log(respuesta.data);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    obtenerDatos();
  }, [show]);

  useEffect(() => {
    if (!loading) {
      const table = $("#tablehistorial").DataTable();
      if ($.fn.DataTable.isDataTable(table)) {
        table.destroy();
      }
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          sProcessing: "Procesando...",
          sLengthMenu: "Mostrar _MENU_ registros",
          sZeroRecords: "No se encontraron resultados",
          sEmptyTable: "Ningún dato disponible en esta tabla",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
          sLoadingRecords: "Cargando...",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending:
              ": Activar para ordenar la columna de manera ascendente",
            sSortDescending:
              ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copy: "Copiar",
            colvis: "Visibilidad",
          },
        },
      });
      $("#tablehistorial").DataTable();
    }
  }, [loading, show]);

  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-custom" centered style={{fontSize:'12px'}}>
      <Modal.Header closeButton className="bg-danger">
        <Modal.Title style={{ color: "#fff", textAlign: "center" ,width:'100%'}}>
          Bitácora General
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mb-3" style={{width:'100%',gap:'15px',alignItems:'end'}}>
          <div style={{display:'flex',flexDirection:'column',gap:'15px',width:'50%'}}>
            <label htmlFor="" className="font-Responsive">Buscar</label>
            <Form.Select value={tipofiltro} onChange={handleTipoFiltroChange} className="font-Responsive">
            <option value="bpordia">Por Día</option>
            <option value="bpormes">Por Mes</option>
          </Form.Select>
          </div>
          {tipofiltro === "bpordia" ? (
                      <div style={{display:'flex',flexDirection:'column',gap:'15px',width:'50%'}} >
            <label htmlFor="" className="font-Responsive">Seleccione la Fecha</label>
            <input
            className="form-control font-Responsive"
              type="date"
              value={fecha.split("/").reverse().join("-")}
              onChange={(e) => setFecha(formatDate(new Date(e.target.value)))}
            />
             </div>
          ) : (
            <div style={{display:'flex',flexDirection:'column',gap:'15px',width:'50%'}} >
            <label htmlFor="" className="font-Responsive">Seleccione el mes</label>
            <Form.Select value={mes} onChange={(e) => setMes(e.target.value)} className="font-Responsive">
              <option value="01">Enero</option>
              <option value="02">Febrero</option>
              <option value="03">Marzo</option>
              <option value="04">Abril</option>
              <option value="05">Mayo</option>
              <option value="06">Junio</option>
              <option value="07">Julio</option>
              <option value="08">Agosto</option>
              <option value="09">Septiembre</option>
              <option value="10">Octubre</option>
              <option value="11">Noviembre</option>
              <option value="12">Diciembre</option>
            </Form.Select>
            </div>
          )}
          <Button onClick={obtenerDatos} style={{height:'40px',width:'10%'}} className="font-Responsive botonfiltro-hper">Filtrar</Button>
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        ) : (
          <div className="card col-sm-12" style={{ maxWidth: "100%" }}>
            <div className="card-body">
              <div className="table-responsive-xl">
                <table
                  id="tablehistorial"
                  className="table table-striped text-center"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                    <th className="text-center">Registrado</th>
                    <th className="text-center">Título</th>
                        <th className="text-center">Acción</th>
                        <th className="text-center">Categoría</th>
                        <th className="text-center small-col">Año</th>
                        <th className="text-center small-col">Numero de Documento</th>
                        <th className="text-center">Gravedad</th>
                        <th className="text-center">Tipo</th>
                        <th className="text-center">Medida</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historialdefaltas !== null &&
                      historialdefaltas.map((index) => (
                        <tr key={index.data.histoper_ch_idcodenlafk}>
                          <td>{index.data.histoper_ch_idcodenlafk}</td>
                          <td>{index.data.histoper_ch_titu}</td>
                            <td>{index.data.ACCI_chDES}</td>
                            <td>
                              {index.data.histoper_ch_categoriafk
                                ? index.data.histoper_ch_categoriafk
                                : "No registra"}
                            </td>
                            <td style={{width: 100}}>
                              {index.data.histoper_ch_anno
                                ? index.data.histoper_ch_anno
                                : "No registra"}
                            </td>
                            <td>
                              {index.data.histoper_ch_nro
                                ? index.data.histoper_ch_nro
                                : "No registra"}
                            </td>
                            <td>{index.data.FALT_chDES}</td>
                            <td>{index.data.TIPO_chDES}</td>
                            <td>
                              {index.data.MEDI_chDES }
                            </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button variant="danger" onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HistorialFaltas;