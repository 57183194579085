// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Aseguramos que el modal de imagen ampliada tenga el tamaño justo de la imagen */
.custom-modal .modal-dialog {
    margin: 0;
    padding: 0;
    width: auto; /* Se ajusta al tamaño de la imagen */
    height: auto; /* Se ajusta al tamaño de la imagen */
  }
  
  .custom-modal .modal-content {
    width: auto; /* El contenido se ajusta al tamaño de la imagen */
    height: auto; /* El contenido se ajusta al tamaño de la imagen */
    border: none; /* Elimina bordes innecesarios */
    box-shadow: none; /* Elimina cualquier sombra alrededor del modal */
  }
  
  .custom-modal .modal-body {
    padding: 0; /* Elimina el padding extra */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Ajusta la imagen para que tenga un tamaño adecuado */
  .custom-modal img {
    width: auto; /* La imagen mantiene su tamaño original */
    max-width: 100%; /* Asegura que no se desborde */
    height: auto; /* Mantiene la proporción */
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/CSS/fotos.css"],"names":[],"mappings":"AAAA,kFAAkF;AAClF;IACI,SAAS;IACT,UAAU;IACV,WAAW,EAAE,qCAAqC;IAClD,YAAY,EAAE,qCAAqC;EACrD;;EAEA;IACE,WAAW,EAAE,kDAAkD;IAC/D,YAAY,EAAE,kDAAkD;IAChE,YAAY,EAAE,gCAAgC;IAC9C,gBAAgB,EAAE,iDAAiD;EACrE;;EAEA;IACE,UAAU,EAAE,6BAA6B;IACzC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA,uDAAuD;EACvD;IACE,WAAW,EAAE,0CAA0C;IACvD,eAAe,EAAE,+BAA+B;IAChD,YAAY,EAAE,2BAA2B;EAC3C","sourcesContent":["/* Aseguramos que el modal de imagen ampliada tenga el tamaño justo de la imagen */\r\n.custom-modal .modal-dialog {\r\n    margin: 0;\r\n    padding: 0;\r\n    width: auto; /* Se ajusta al tamaño de la imagen */\r\n    height: auto; /* Se ajusta al tamaño de la imagen */\r\n  }\r\n  \r\n  .custom-modal .modal-content {\r\n    width: auto; /* El contenido se ajusta al tamaño de la imagen */\r\n    height: auto; /* El contenido se ajusta al tamaño de la imagen */\r\n    border: none; /* Elimina bordes innecesarios */\r\n    box-shadow: none; /* Elimina cualquier sombra alrededor del modal */\r\n  }\r\n  \r\n  .custom-modal .modal-body {\r\n    padding: 0; /* Elimina el padding extra */\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  \r\n  /* Ajusta la imagen para que tenga un tamaño adecuado */\r\n  .custom-modal img {\r\n    width: auto; /* La imagen mantiene su tamaño original */\r\n    max-width: 100%; /* Asegura que no se desborde */\r\n    height: auto; /* Mantiene la proporción */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
