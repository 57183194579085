import { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import API_ASISTENCIA from '../../../../componentes/config/apisAsistencia_Variables';
import '../../../CSS/fotos.css'; // Ajusta la ruta según sea necesario

const ModalFotos = ({ showModal, handleClose, fecha }) => {
  const [images, setImages] = useState([]); // Almacena las imágenes
  const [photoCount, setPhotoCount] = useState(0); // Cuenta las fotos
  const [selectedImage, setSelectedImage] = useState(null); // Imagen seleccionada
  const [selectedImageNumber, setSelectedImageNumber] = useState(null); // Número de imagen seleccionada
  const [selectedDate, setSelectedDate] = useState(fecha || new Date().toISOString().split('T')[0]); // Fecha seleccionada
  const [loading, setLoading] = useState(false); // Estado de carga de imágenes

  // Formato de fecha (ddmmyyyy)
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}${month}${year}`;
  };

  // Fetch de imágenes de la API
  const fetchImagesFromAPI = async (date) => {
    setLoading(true); // Activar el estado de carga antes de hacer la solicitud
    try {
      const formattedDate = formatDate(date);
      const endpoint = API_ASISTENCIA.consultafotos('bpordia', formattedDate);
      const response = await fetch(endpoint);

      if (!response.ok) {
        throw new Error('Error al obtener las fotos');
      }

      const data = await response.json();
      setImages(data.images.reverse());
      setPhotoCount(data.images.length);
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      setLoading(false); // Desactivar el estado de carga después de la solicitud
    }
  };

  // Manejo de click en una imagen
  const handleImageClick = (imageSrc, index) => {
    setSelectedImage(imageSrc);
    setSelectedImageNumber(index + 1);
  };

  // Llamar a la API cuando se seleccione una fecha
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleFilterClick = () => {
    fetchImagesFromAPI(selectedDate);
  };

  useEffect(() => {
    fetchImagesFromAPI(selectedDate);
  }, [selectedDate]);

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>TOTAL DE MARCACIONES: {photoCount}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Contenedor del selector de fecha y botón de filtro */}
          <div className="filter-container mb-3">
            <div className="row">
              <div className="col-3">
                <input
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className="form-control"
                />
              </div>
              <div className="col-2">
                <Button variant="primary" onClick={handleFilterClick}>
                  Filtrar
                </Button>
              </div>
            </div>
          </div>

          {/* Mostrar Spinner mientras se cargan las imágenes */}
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <p>Cargando imágenes...</p>
            </div>
          ) : (
            // Contenedor de imágenes con 5 por fila
            <div className="d-flex flex-wrap justify-content-start">
              {images.map((imageBase64, index) => (
                <div key={index} className="m-1" style={{ width: '18%' }}>
                  <img
                    src={`data:image/jpeg;base64,${imageBase64}`}
                    alt={`Imagen ${index + 1}`}
                    style={{ width: '100%', cursor: 'pointer' }}
                    onClick={() => handleImageClick(`data:image/jpeg;base64,${imageBase64}`, index)}
                  />
                  <div className="mt-1 text-center bg-primary text-white">
                    Imagen {index + 1}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para imagen ampliada */}
      {selectedImage && (
        <Modal
          show
          onHide={() => setSelectedImage(null)}
          centered
          dialogClassName="custom-modal" // Usamos esta clase para el modal
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <Modal.Header closeButton style={{ border: 'none' }}>
            <Modal.Title>Imagen ampliada {selectedImageNumber}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center" style={{ padding: 0 }}>
            <img
              src={selectedImage}
              alt="Imagen ampliada"
              className="img-fluid"
              style={{
                width: 'auto',  // El ancho es automático, dependiendo de la imagen
                height: 'auto', // La altura es proporcional
              }}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ModalFotos;
