import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import ListadoAcciones from "./CreateBitacora/ListadoAcciones";
import axios from "axios";
import API_PERSONALSEGURIDAD from "../../../componentes/config/apisPersonalS_Variables";

function CreateBitacora({
  show,
  onHide,
  formData,
  handleChange,
  handleSubmit,
  acciones,
  selectedAccion,
  setSelectedAccion,
  handleFileChange,
  handleGenerateCode,
}) {
  const [ModalAcciones, setModalAcciones] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState(null);

  // Actualiza selectedOption y selectedCategoria cuando formData cambia (para edición)
  useEffect(() => {
    if (formData.histoper_in_idaccionesfk && acciones.length > 0) {
      const accion = acciones.find(
        (a) => a.ACCI_P_inID === formData.histoper_in_idaccionesfk
      );
      setSelectedAccion(
        accion
          ? {
              value: accion.ACCI_P_inID,
              label: `${accion.ACCI_chCOD} - ${accion.ACCI_chDES}`,
            }
          : null
      );
    }
  }, [formData.histoper_in_idaccionesfk, acciones]);

  useEffect(() => {
    if (formData.histoper_ch_categoriafk && categorias.length > 0) {
      const categoria = categorias.find(
        (c) => c.param_CH_codcatParam === formData.histoper_ch_categoriafk
      );
      setSelectedCategoria(
        categoria
          ? {
              value: categoria.param_CH_codcatParam,
              label: categoria.param_CH_nomParam,
            }
          : null
      );
    }
  }, [formData.histoper_ch_categoriafk, categorias]);

  const handleAccionChange = (selectedOption) => {
    console.log("accion seleccionada:", selectedOption);

    setSelectedAccion(selectedOption);
    handleChange({
      target: {
        name: "histoper_in_idaccionesfk",
        value: selectedOption ? selectedOption.value : "",
      },
    });
  };

  const handleCategoriaChange = (selectedOption) => {
    console.log("Categoría seleccionada:", selectedOption);
    setSelectedCategoria(selectedOption);
    handleChange({
      target: {
        name: "histoper_ch_categoriafk",
        value: selectedOption ? selectedOption.value : "",
      },
    });
  };

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get(
          API_PERSONALSEGURIDAD["listadocategorias"]
        );
        setCategorias(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategorias();
  }, []);

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton className="bg-warning">
        <Modal.Title
          className="font-Responsive"
          style={{ color: "#000", textAlign: "center" }}
        >
          {formData.ACCI_P_inID
            ? "Editar Bitácora"
            : "Registro de incidencia del Personal"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div
              className="row bitacoradocumento"
              style={{ display: "flex", width: "100%" }}
            >
              <div style={{ width: "60%" }}>
                <div className="form-group">
                  <label htmlFor="histoper_ch_titu">Título:</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa-regular fa-file-lines"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control font-Responsive"
                      name="histoper_ch_titu"
                      value={formData.histoper_ch_titu}
                      onChange={handleChange}
                      placeholder="Ingrese el Título"
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: "40%" }}>
                <div className="form-group">
                  <label htmlFor="">Código:</label>
                  <div className="input-group mb-3">
                    <button
                      className="btn btn-success input-group-text font-Responsive"
                      onClick={handleGenerateCode}
                      disabled={formData.histoper_ch_nro}
                    >
                      <i className="fa-solid fa-barcode"></i>
                    </button>
                    <input
                      type="text"
                      className="form-control font-Responsive"
                      name="histoper_ch_nro"
                      value={formData.histoper_ch_nro}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "10px", display: "flex" }}>
              <div
                className="row bitacoradocumento"
                style={{ display: "flex", width: "50%" }}
              >
                <div style={{ width: "50%" }}>
                  <div className="form-group">
                    <label htmlFor="">N°:</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-excel"></i>
                      </span>
                      <input
                        type="text"
                        name="histoper_ch_docnro"
                        className="form-control font-Responsive"
                        value={formData.histoper_ch_docnro}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <div className="form-group">
                    <label htmlFor="">Año:</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="fa-solid fa-calendar-check"></i>
                      </span>
                      <input
                        type="text"
                        name="histoper_ch_docanno"
                        className="form-control font-Responsive"
                        value={formData.histoper_ch_docanno}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bitacoracategoria" style={{ width: "50%" }}>
                <div className="form-group">
                  <label htmlFor="histoper_ch_categoriafk">
                    Categoría de Acción:
                  </label>
                  <Select
                    className="font-Responsive"
                    options={categorias.map((categoria) => ({
                      value: categoria.param_CH_nomParam,
                      label: categoria.param_CH_nomParam,
                    }))}
                    value={selectedCategoria}
                    onChange={handleCategoriaChange}
                    isSearchable
                    placeholder="Selecciona una categoría"
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "10px", display: "flex" }}>
              <div className="divAccion" style={{ width: "85%" }}>
                <div className="form-group">
                  <label htmlFor="histoper_in_idaccionesfk">Acción:</label>
                  <Select
                    className="font-Responsive"
                    options={acciones.map((accion) => ({
                      value: accion.ACCI_P_inID,
                      label: `${accion.ACCI_chCOD} - ${accion.ACCI_chDES}`,
                    }))}
                    value={selectedAccion}
                    onChange={handleAccionChange}
                    isSearchable
                    placeholder="Selecciona una acción"
                  />
                </div>
              </div>
              <div
                className="divBotonListar"
                style={{
                  width: "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                  paddingLeft: "0",
                }}
              >
                <button
                  type="button"
                  className="btn btn-dark font-Responsive"
                  title="Listar Acciones"
                  style={{ width: "100%", height: "60%", fontSize: "15px" }}
                  onClick={() => setModalAcciones(true)}
                >
                  <strong>Listar </strong>
                  <i className="fa-solid fa-folder-plus"></i>
                </button>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-12 col-md-12"
              style={{ marginTop: "10px" }}
            >
              <div className="form-group">
                <label htmlFor="histoper_ch_descrip">Descripcion:</label>
                <textarea
                  type="text"
                  name="histoper_ch_descrip"
                  className="form-control font-Responsive"
                  value={formData.histoper_ch_descrip}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-12 col-md-12"
              style={{ marginTop: "10px" }}
            >
              <div className="forma-group">
                <label htmlFor="">Archivo:</label>
                <input
                  className="form-control font-Responsive"
                  type="file"
                  accept=".pdf, .doc, .docx"
                  style={{ width: "100%" }}
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
        </div>
        <ListadoAcciones
          show={ModalAcciones}
          onHide={() => setModalAcciones(false)}
          setSelectedAccion={setSelectedAccion}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="Primary"
          className="btn btn-success font-Responsive"
          onClick={handleSubmit}
        >
          {formData.ACCI_P_inID ? "Actualizar" : "Registrar"}
        </Button>
        <Button variant="danger" onClick={onHide} className="font-Responsive">
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateBitacora;
