const API_ASISTENCIA = {
  funciones: 'https://api01.cloudlab.net.pe/api50h_param_read_funasis_get/',
  tipoRegistros : 'https://api01.cloudlab.net.pe/api50i_param_read_tipregasis_get/',
  radios: 'https://api01.cloudlab.net.pe/pi40frad_catrecur_listget/?skip=0&limit=1000',
  camionetas: 'https://api01.cloudlab.net.pe/pi40fcamio_catrecur_listget/?skip=0&limit=1000',
  motos: 'https://api01.cloudlab.net.pe/pi40fmotos_catrecur_listget/?skip=0&limit=1000',
  puestosFijos: 'https://api01.cloudlab.net.pe/pi40fpuestosfijos_catrecur_listget/?skip=0&limit=1000',
  bicicletas: 'https://api01.cloudlab.net.pe/pi40fbicicle_catrecur_listget/?skip=0&limit=1000',
  usuarios: 'https://api01.cloudlab.net.pe/pi120_M_MAES_SEREN_LIST/?skip=0&limit=1000',
  serenosPorDNI: (documento) => `https://api01.cloudlab.net.pe/pi121_M_MAES_SEREN_DNIQR/${documento}/1/`,
  ConsultarRol: (documento) => `https://api01.cloudlab.net.pe/pi0401_RolAsig_listget/?VSERE_P_chDNI=${documento}&vcodopc_rol=ROL01`,
  ConsultaRolMes: (dnilogin) =>  `https://api01.cloudlab.net.pe/pi0401_RolAsig_listget/?VSERE_P_chDNI=${dnilogin}&vcodopc_rol=ROL02`,
  postasistencia:(tipoFecha,fechaSelec,horaSelec) =>  `https://api01.cloudlab.net.pe/pi011e_asis_sereno_entsal/${tipoFecha === "fecmod" ? "?opc_fecha=fecmod&mod_fechahor=" :"?opc_fecha=fechoy"}${tipoFecha === "fecmod" ? `${fechaSelec} ${horaSelec}:00` : "" }`,
  mostrarfotodeusuario: (documento) => `https://api01.cloudlab.net.pe/pi20b100-download-archisql/${documento}/fotoperfil/1/`,
  enviarfotoevidencia: (documentousuario,documentoresponsable,latitud,longitud) => `https://api01.cloudlab.net.pe/pi11fil_upload_fil_asissql/?tipo_archivo=img&varchi_ch_codenlp=${documentousuario}&varchi_ch_codenls=${documentoresponsable}&varchi_ch_tiporeg=ASIS&varchifoto_in_act=1&vlat=${latitud}&vlon=${longitud}`,

  consultafotos: (vopc_todos, vvalor_buscado) => {
    return `http://192.168.1.60:5005/pi14filjson_iddownload_fil_asissql/?vopc_todos=${vopc_todos}&vvalor_buscado=${vvalor_buscado}`;
  },
};

export default API_ASISTENCIA;
