import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from "react-select";
import Swal from 'sweetalert2';
import API_ASISTENCIA from '../../../componentes/config/apisAsistencia_Variables';
import credenciales from '../../../componentes/config/credenciales';

const ModalActualizarCapacidad = ({ showModal2, handleClose2, selectedId }) => {
  const [funcion, setFuncion] = useState(null);
  const [total, setTotal] = useState('');
  const [error, setError] = useState('');
  const [funciones, setFunciones] = useState([]);

  // Obtener datos para funciones
  const fetchFunciones = async () => {
    const username = credenciales.usuario;
    const password = credenciales.contrasena;
    const encodedCredentials = btoa(`${username}:${password}`);

    try {
      const response = await fetch(API_ASISTENCIA.funciones, {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const result = await response.json();
        const opcionesFunciones = result.funciones.map(funcion => ({
          value: funcion,
          label: funcion,
        }));
        setFunciones(opcionesFunciones);
      } else {
        Swal.fire('Error', 'Error al obtener las funciones', 'error');
      }
    } catch (err) {
      Swal.fire('Error', 'Hubo un error al conectar con la API', 'error');
    }
  };

  // Obtener datos del registro seleccionado
  const fetchRegistro = async () => {
    const username = credenciales.usuario;
    const password = credenciales.contrasena;
    const encodedCredentials = btoa(`${username}:${password}`);
  
    try {
      const response = await fetch(API_ASISTENCIA.capacidadAsisDetail(selectedId), {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const registro = await response.json();
        
        // Verificamos que los datos estén correctos
        console.log('Datos obtenidos desde la API:', registro);
  
        // Establecemos los valores en el estado
        setFuncion({ value: registro.data.CAP_chFUN, label: registro.data.CAP_chFUN });
        setTotal(registro.data.CAP_IN_TOTAL);
      } else {
        Swal.fire('Error', 'Error al cargar el registro', 'error');
      }
    } catch (err) {
      Swal.fire('Error', 'Hubo un error al conectar con la API', 'error');
      console.error('Error de conexión:', err);
    }
  };
  

  // Cargar funciones y datos del registro al abrir el modal
  useEffect(() => {
    if (showModal2 && selectedId) {
      fetchFunciones();
      fetchRegistro();
    }
  }, [showModal2, selectedId]);
  
  // Limpiar el estado al cerrar el modal
  useEffect(() => {
    if (!showModal2) {
      setFuncion(null);
      setTotal('');
      setError('');
    }
  }, [showModal2]);
  

  // Actualizar registro
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      CAP_chFUN: funcion?.value,
      CAP_IN_TOTAL: total,
    };

    const username = credenciales.usuario;
    const password = credenciales.contrasena;
    const encodedCredentials = btoa(`${username}:${password}`);

    try {
      const response = await fetch(API_ASISTENCIA.capacidadAsisUpdate(selectedId), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${encodedCredentials}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        Swal.fire('Éxito', 'Registro actualizado con éxito', 'success');
        handleClose2(); // Cerrar modal al finalizar
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Error desconocido al actualizar');
        Swal.fire('Error', errorData.message || 'Error al actualizar el registro', 'error');
      }
    } catch (err) {
      setError('Hubo un error al conectar con la API');
      Swal.fire('Error', 'Hubo un error al conectar con la API', 'error');
    }
  };

  return (
    <Modal show={showModal2} onHide={handleClose2}>
      <Modal.Header closeButton>
        <Modal.Title>Actualizar Capacidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={handleSubmit}>
  <Form.Group controlId="funcion">
    <Form.Label>Función</Form.Label>
    <Select
      value={funcion} // Aquí se muestra la función seleccionada
      onChange={setFuncion} // Permite cambiar la función si es necesario
      options={funciones} // Asegúrate de que las opciones estén bien definidas
      required
    />
  </Form.Group>

  <Form.Group controlId="total">
    <Form.Label>Total</Form.Label>
    <Form.Control
      type="text"
      value={total} // El total es editable ahora
      onChange={(e) => setTotal(e.target.value)} // Permite modificar el total
    />
  </Form.Group>

  <div className="d-flex justify-content-between">
    <Button variant="secondary" onClick={handleClose2}>
      Cancelar
    </Button>
    <Button type="submit" variant="primary">
      Actualizar
    </Button>
  </div>
</Form>

        {error && <div className="mt-2 text-danger">{error}</div>}
      </Modal.Body>
    </Modal>
  );
};

export default ModalActualizarCapacidad;
