/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/*IMPORTACIONES*/
import React, { useEffect, useRef, useState } from 'react';
import '../CSS/Principal.css';
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs5';
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
/*FUNCION PRINCIPAL */
function GestionPagos(){
  /*DECLARO UN ARRAY PARA OBTENER TODOS LOS DATOS */
  const [data, setData] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingBotonCCI, setLoadingBotonCCI] = useState(false);

  const dataTableRef = useRef(null);
  

  const obtenerDatos = async () => {
    try {
      const respuesta = await axios.get(
        `http://190.117.85.58:5005/pi120_M_MAES_SEREN_LIST/?limit=1000`
      );
      setData(respuesta.data);
      console.log(respuesta.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  useEffect(() => {
    obtenerDatos();

  }, []);
  useEffect(() => {
    if (data.length > 0) {
      if ($.fn.DataTable.isDataTable('#example')) {
        dataTableRef.current.destroy();
      }
      dataTableRef.current = $('#example').DataTable({
        columnDefs: [
          { width: '13%', targets: 0 }, // Acciones
          { width: '10%', targets: 1 }, // Documento
          { width: '20%', targets: 2 }, // Nombres y Apellidos
          { width: '10%', targets: 3 }, // Nacimiento
          { width: '10%', targets: 4 }, // Sexo
          { width: '10%', targets: 5 }, // Ruc
        ],
      });
    }
    return () => {
      if ($.fn.DataTable.isDataTable('#example')) {
        dataTableRef.current.destroy();
      }
    };
  }, [data]);

  const fetchGeneratePDF = async() => {
    setLoadingPDF(true);
    try {
      const response = await axios.get(
        `http://190.117.85.58:5020/pi803_repor_formatCCI_bloque_pdf`,
        { responseType: 'arraybuffer' } 
      );
  
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        
        // Abre el PDF en una nueva ventana o pestaña
        const newWindow = window.open(url, '_blank');
        if (!newWindow) {
          // Si el bloqueador de ventanas emergentes está activo, muestra un mensaje de advertencia
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "La previsualización del PDF ha sido bloqueada por el navegador. Por favor, desbloquea las ventanas emergentes e intenta nuevamente.",
          });
        }
  
        // Cerrar la ventana o pestaña después de que se haya cargado el PDF
        newWindow.onload = () => {
          setTimeout(() => {
            newWindow.document.title = `REPORTE_CCI_PERSONALMLV.pdf`;
          }, 500);
          setLoadingPDF(false); // Marcar la carga como completa
        };
      } else {
        Swal.fire({
          icon: "error",
          title: "Error en la Descarga",
          text: "No se pudo descargar la ficha personal",
        });
      } 
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error en la Descarga",
        text: "No se pudo Conectar con el servidor",
      });
    }
  };
  const fetchGenerateCCIPersonal = async (dni, preview = false) => {
    setLoadingBotonCCI(true);
    try {
      const response = await axios.get(
        `http://190.117.85.58:5020/pi802_repor_formatCCI_pdf?dni_personal=${dni}`,
        { responseType: 'arraybuffer' } 
      );
  
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        
        if (preview) {
          const newWindow = window.open(url, '_blank');
          setTimeout(() => {
            newWindow.document.title = `REPORTE_CCI_MLV_${dni}.pdf`;
          }, 500);
        } else {
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `REPORTE_CCI_MLV_${dni}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }

      } else {
        Swal.fire({
          icon: "error",
          title: "Error en la Descarga",
          text: "No se encontró el documento",
        }).then()      
      } 
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error en la Descarga",
        text: "No se pudo Conectar con el servidor",
      }).then()       
    }
    setLoadingBotonCCI(false);
  }
  const handleLogout = () => {

    Cookies.remove("rol");
    Cookies.remove("nombre");
    Cookies.remove("apellidopat");
    Cookies.remove("apellidomat");
    Cookies.remove("dnilogin");
    Cookies.remove("miip");

    // Redirect to the login page or any other desired page
    window.location.replace('/');
  };
  return (
    <div style={{width:'100%'}}>
     <header style={{display:'flex',justifyContent:'center',alignItems:'center' , marginTop:'10px'}}>
      <img src={require("../../componentes/logoerlan.png")} style={{width:'15%', height:'80px', objectFit:'cover'}} className='logoerlan'/>
    </header>
    <nav>
    <a className="atras" href="/principal"><i class="fa-solid fa-arrow-rotate-left"></i> Atras</a>
    <a className="salir" onClick={handleLogout}><i className="fa-solid fa-users-slash"></i> Cerrar Sesión</a>
    </nav>
    <div >
      <br/>
    </div>
    <div className="card col-sm-12 cardprincipal" style={{ maxWidth: '100%' }}>
      <div class="card-body">
      <button
          type="button"
          className="btn btn-warning mb-1 botonps"
          onClick={() => fetchGeneratePDF()}
          Title="Generar Formato CCI"
        >
        {loadingPDF ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: '5px' }}
            />
            Descargando...
          </>
        ) : (
          <>
            <strong>Generar Formatos CCI </strong>
            <i class="fa-solid fa-folder-open"></i>
          </>
        )}
      </button>

      <hr style={{ width: '100%' }}/>
        <div className="table-responsive-xl">
          <table id="example" className="table table-striped " style={{ width: '100%' }}>
            <thead >
                <tr >
                    <th>Acciones</th>
                    <th>Documento</th>
                    <th>Nombres y Apellidos</th>
                    <th>Nacimiento</th>
                    <th>Sexo</th>
                    <th>Ruc</th>

                </tr>
            </thead>
            <tbody>
            {data !== null && data.map((index,intro) => (
              <tr key={intro.SERE_P_chDNI}>
              <td>
              <button
                  type="button"
                  class="btn btn-danger btn-sm m-1 custom-tooltip"
                  onClick={() => fetchGenerateCCIPersonal(index.SERE_P_chDNI, true)} 
                  title="Descargar Formato cci">
                  {loadingBotonCCI ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </>
                  ) : (
                    <>
                      <strong>CCI </strong><i class="fa-solid fa-file-invoice"></i>
                    </>
                  )}
                </button>
              </td>
                <td>{index.SERE_P_chDNI}</td>
                <td>{index.SERE_chNOM} {index.SERE_chAPEPAT} {index.SERE_chAPEMAT}</td>
                <td>{index.SERE_dtFECNAC}</td>
                <td>{index.SERE_chSEXO}</td>
                <td>{index.SERE_chRUC}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
    </div>
  </div>
</div>
  )
}
/*FIN DE LA FUNCION PRINCIPAL */

export default GestionPagos