/* eslint-disable jsx-a11y/alt-text */
/*IMPORTACIONES*/
import React, { useEffect, useRef, useState } from 'react';
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs5';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import FormUsuario from '../Usuario/FormUsuario';
import ModalImagenes from './ModalImagenes';
import { CSVLink } from 'react-csv';
import HistorialFaltas from './HistorialFaltas';
import FichaPersonal from './FichaPersonal';
import Cookies from 'js-cookie';
import API_PERSONALSEGURIDAD from '../../componentes/config/apisPersonalS_Variables';
import RegistrosUsuario from '../Asistencia/Modal/RegistrosUsuario';
import ModalFotos from './Modals/Fotos/Asistencia';
import ModalReporte from './Modals/Reportes/Asistencia';
import AsitenciasBitacora from './Modals/AsitenciasBitacora';

/*FUNCION PRINCIPAL */
function PersonalSeguridad() {
  /*DECLARO UN ARRAY PARA OBTENER TODOS LOS DATOS */
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showImagenesModal, setShowImagenesModal] = useState(false);
  const [showModalFaltas, setShowModalFalta] = useState(false);
  const [selectedImagen, setSelectedImagen] = useState(null);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [archivo, setArchivo] = useState(null);
  const [tipoarchivo, setTipoArchivo] = useState('img');
  const [clasearchivo, setClaseArchivo] = useState('');
  const [estadofoto, setEstadoFoto] = useState('1');
  const dataTableRef = useRef(null);
  const [selectedDocumento, setSelectedDocumento] = useState(null);
  const [imagenPreview, setImagenPreview] = useState(null);
  const [showFormUsuarioModal, setShowFormUsuarioModal] = useState(false);
  const [showHistorialFaltas, setShowHistorialFaltas] = useState(false);
  const [showFichaPersonal, setShowFichaPersonal] = useState(false);
  const [showModalAsistencias, setShowModalAsistencias] = useState(false);
  const [selectedPersonaAsistencia, setSelectedPersonaAsistencia] = useState(null);
  const [selectedFichaPersona, setSelectedFichaPersona] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [showModalFotos, setShowModalFotos] = useState(false);
  const [showModalReporte, setShowModalReporte] = useState(false);
  const [showModalBitAsis, setShowModalBitAsis] = useState(false);
  const [selectedFecha, setSelectedFecha] = useState(null);
  const [nombresApellidos, setNombresApellidos] = useState('')
  const headers = [
    { label: 'Documento', key: 'SERE_P_chDNI' },
    { label: 'Nombre', key: 'SERE_chNOM' },
    { label: 'Apellido Paterno', key: 'SERE_chAPEPAT' },
    { label: 'Apellido Materno', key: 'SERE_chAPEMAT' },
    { label: 'Nacimiento', key: 'SERE_dtFECNAC' },
    { label: 'Sexo', key: 'SERE_chSEXO' },
    { label: 'Ruc', key: 'SERE_chRUC' }
  ];
  const obtenerDatos = async () => {
    try {
      const respuesta = await axios.get(API_PERSONALSEGURIDAD['listarPersonal']);
      setData(respuesta.data);
      console.log(respuesta.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  // Función para mostrar el modal
  const handleShowFotos = () => {
    const fechaActual = new Date(); // Cambia esta lógica si necesitas una fecha específica
    setSelectedFecha(fechaActual);
    setShowModalFotos(true);
  };

  const handleShowReporte = () => {
    const fechaActual = new Date();
    setSelectedFecha(fechaActual);
    setShowModalReporte(true);
  };

  // Función para cerrar el modal
  const handleCloseFotos = () => {
    setShowModalFotos(false);
    setSelectedFecha(null); // Opcional, si necesitas limpiar la fecha
  };

  const handleCloseReporte = () => {
    setShowModalReporte(false);
    setSelectedFecha(null); // Opcional, si necesitas limpiar la fecha
  };


  useEffect(() => {
    obtenerDatos();

  }, []);
  const handleOnSubmitSuccess = () => {
    obtenerDatos();
  };
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setClaseArchivo(selectedValue);
  };
  const handleChangeestado = (event) => {
    const selectedValue = event.target.value;
    setEstadoFoto(selectedValue);
  };
  useEffect(() => {
    if (data.length > 0) {
      if ($.fn.DataTable.isDataTable('#example')) {
        dataTableRef.current.destroy();
      }
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          "sProcessing": "Procesando...",
          "sLengthMenu": "Mostrar _MENU_ registros",
          "sZeroRecords": "No se encontraron resultados",
          "sEmptyTable": "Ningún dato disponible en esta tabla",
          "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
          "sInfoPostFix": "",
          "sSearch": "Buscar:",
          "sUrl": "",
          "sInfoThousands": ",",
          "sLoadingRecords": "Cargando...",
          "oPaginate": {
            "sFirst": "Primero",
            "sLast": "Último",
            "sNext": "Siguiente",
            "sPrevious": "Anterior"
          },
          "oAria": {
            "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "buttons": {
            "copy": "Copiar",
            "colvis": "Visibilidad"
          }
        }
      });
      dataTableRef.current = $('#example').DataTable({
        columnDefs: [
          { width: '20%', targets: 0 }, // Acciones
          { width: '5%', targets: 1 }, // Nombres
          { width: '10%', targets: 2 }, // Nacimiento
          { width: '25%', targets: 3 }, // Sexo
          { width: '5%', targets: 4 }, // Ruc
          { width: '5%', targets: 5 }, // Ruc

        ],
      });
    }
    return () => {
      if ($.fn.DataTable.isDataTable('#example')) {
        dataTableRef.current.destroy();
      }
    };
  }, [data]);
  const handleModalOpen = (documento) => {
    setShowModal(true);
    setSelectedDocumento(documento);
  };
  const handleModalImagenesOpen = (documento) => {
    setShowImagenesModal(true);
    setSelectedImagen(documento);
  };


  const handleModalFaltasOpen = (documento, nombres) => {
    setShowModalFalta(true);
    setSelectedPersona(documento);
    setNombresApellidos(nombres)
  };

  const handleModalFichaPersonal = (documento) => {
    setShowFichaPersonal(true);
    setSelectedFichaPersona(documento);
  };
  const handleModalAsistencias = (documento) => {
    setShowModalAsistencias(true);
    setSelectedPersonaAsistencia(documento);
  };
  const clearEditUserData = () => {
    setEditUserData(null);
    setIsEditMode(false);
  };
  const handleModalEditOpen = (documento) => {
    const userToEdit = data.find((user) => user.SERE_P_chDNI === documento);

    if (userToEdit) {
      setEditUserData(userToEdit);
      setIsEditMode(true);
    } else {
      setEditUserData(null);
      setIsEditMode(false);
    }
    setShowFormUsuarioModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleTipoArchivoChange = (tipo) => {
    setTipoArchivo(tipo);
  };
  const handleFileChange = (event) => {
    const selectedArchivo = event.target.files[0];
    setArchivo(selectedArchivo);
    if (selectedArchivo) {
      const imagenPreviewUrl = URL.createObjectURL(selectedArchivo);
      setImagenPreview(imagenPreviewUrl);
    } else {
      setImagenPreview(null);
    }
  };
  const handleUpload = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('file', archivo);
      console.log(archivo)
      const response = await axios.post(
        API_PERSONALSEGURIDAD['postfotoarchivo'](tipoarchivo, selectedDocumento, clasearchivo, estadofoto),
        formData
      );

      if (response.status >= 200 && response.status < 300) {
        console.log('Archivo subido correctamente');
        Swal.fire({
          icon: 'success',
          title: 'Subida Exitosa',
          text: 'La imagen se subió correctamente.',
        }).then(() => {
          setShowModal(false);
          setImagenPreview(null);

        });
      } else {
        console.log('Fallo en la subida del archivo');
        Swal.fire({
          icon: 'error',
          title: 'Error al registrar imagen',
          text: 'No se pudo subir la imagen (pi20a-uplo)',
        });
      }
    } catch (error) {
      console.error('Error al subir el archivo:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al registrar imagen',
        text: 'Error en el servidor (pi20a-uplo)',
      });
    }
  };

  const handleLogout = () => {

    Cookies.remove("rol");
    Cookies.remove("nombre");
    Cookies.remove("apellidopat");
    Cookies.remove("apellidomat");
    Cookies.remove("dnilogin");
    Cookies.remove("miip");

    // Redirect to the login page or any other desired page
    window.location.replace('/');
  };
  return (
    <div style={{ width: '100%' }}>
      <header className="d-flex justify-content-center align-items-center py-3">
        <img
          src={require("../../componentes/logo_victoria.png")}
          alt="Logo Victoria"
          className="logoerlan img-fluid"
        />
      </header>
      <nav>
        <a className="atras" href="/principal"><i class="fa-solid fa-arrow-rotate-left"></i> Atras</a>
        <a className="salir" onClick={handleLogout}><i className="fa-solid fa-users-slash"></i> Cerrar Sesión</a>
      </nav>
      <div >
        <br />
      </div>
      
      <div className="container">


        <button
          type="button"
          className="btn btn-sm btn-dark mb-1 botonps"
          onClick={() => setShowFormUsuarioModal(true)}
          Title="Registrar Usuario"
        >
          <i class="fa-solid fa-user-plus"></i>
        </button>

        <button
          type="button"
          className="btn btn-sm btn-success mb-1 ms-1 botonps"
        >
          <CSVLink data={data} headers={headers} filename={'personal_seguridad_data.csv'} className='text-decoration-none'>

          </CSVLink>
          <i class="fa-solid fa-file-csv"></i>
        </button>

        <button
          type="button"
          className="btn btn-sm btn-primary mb-1 ms-1 botonps"
          onClick={handleShowFotos}
          title="Ver Fotos de Asistencias"
        >
          <i className="fas fa-camera"></i>
        </button>

        <button
          type="button"
          className="btn btn-sm btn-info mb-1 ms-1 botonps"
          onClick={() => setShowModalBitAsis(true)}
          Title="Ver Bitácora de Asistencias"
        >
          <i class="fas fa-user-check"></i>
        </button>

        <button
          type="button"
          className="btn btn-sm btn-danger mb-1 ms-1 botonps"
          onClick={handleShowReporte}
          Title="Ver reporte de Asistencias"
        >
          <i class="fas fa-chart-pie"></i>
        </button>

        <div className="table-responsive overflow-auto text-center">
          <table id="example" className="table table-sm table-striped table-hover table-bordered shadow-lg dt-responsive nowrap" style={{ width: '100%' }}>
            <thead >
              <tr >
                <th>NOMBRE</th>
                <th>FUNCIÓN</th>
                <th>SEXO</th>
                <th>ESTADO</th>
                <th>ACCIÓN</th>
                <th>BITACORA</th>
              </tr>
            </thead>
            <tbody>
              {data !== null && data.map((index, intro) => (
                <tr key={intro.SERE_P_chDNI}>

                  <td>{index.SERE_chNOM} {index.SERE_chAPEPAT} {index.SERE_chAPEMAT} | {index.SERE_P_chDNI}</td>
                  <td>{index.SERE_chFUNCION}</td>
                  <td>{index.SERE_chSEXO}</td>
                  <td>
                    {index.SERE_chEST === 'activo' ? (
                      <span className="badge bg-success">ACTIVO</span>
                    ) : index.SERE_chEST === 'nuevo' ? (
                      <span className="badge bg-primary">INGRESANTE</span>
                    ) : (
                      <span className="badge bg-danger">BAJA</span>
                    )}
                  </td>

                  <td>

                    <button
                      type="button"
                      class="btn btn-light btn-sm m-1 custom-tooltip"
                      onClick={() => handleModalEditOpen(index.SERE_P_chDNI)}
                      title="Editar Información">
                      <i class="fas fa-edit"></i>
                    </button>

                    <button
                      type="button"
                      class="btn btn-light btn-sm m-1 custom-tooltip"
                      onClick={() => handleModalFichaPersonal(index.SERE_P_chDNI)}
                      title={`Datos de ${index.SERE_chNOM} ${index.SERE_chAPEPAT} ${index.SERE_chAPEMAT}`}>
                      <i class="fa-solid fa-user-tie"></i>
                    </button>
                  </td>

                  <td>
                    <button type="button"
                      class="btn btn-light btn-sm m-1"
                      onClick={() => handleModalImagenesOpen(index.SERE_P_chDNI)}
                      title="Ver Archivos Subidos">
                      <i class="fa-regular fa-folder-open"></i>
                    </button>
                    <button type="button"
                      class="btn btn-light btn-sm  m-1 "
                      onClick={() => handleModalOpen(index.SERE_P_chDNI)}
                      title="Subir Imagenes o Archivos">
                      <i class="fa-solid fa-file-arrow-up"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-light btn-sm m-1 custom-tooltip"
                      onClick={() => handleModalAsistencias(index.SERE_P_chDNI)}
                      title={`Asistencias de ${index.SERE_chNOM} ${index.SERE_chAPEPAT} ${index.SERE_chAPEMAT}`}>
                      <i class="fa-solid fa-clipboard-list" style={{ color: 'black' }}></i>
                    </button>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>

      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header className='bg-success' closeButton>
          <Modal.Title style={{ color: "#fff", textAlign: "center" }}>Subir Archivos / Imagenes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option1"
              autoComplete="off"
              checked={tipoarchivo === 'img'}
              onChange={() => handleTipoArchivoChange('img')}
            />
            <label className="btn btn-secondary" htmlFor="option1">
              Subir Imagen
            </label>

            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option2"
              autoComplete="off"
              checked={tipoarchivo === 'files'}
              onChange={() => handleTipoArchivoChange('files')}
            />
            <label className="btn btn-secondary" htmlFor="option2">
              Subir Archivo
            </label>
          </div>
          {tipoarchivo === 'img' ?
            <div style={{ marginTop: '10px' }}>
              <div className="alert alert-primary" role="alert">
                Debe ingresar imagen con un peso maximo de 5MB
              </div>
              <div className='row'>
                <div className="col-xs-7 col-sm-7 col-md-7" >
                  <div className="form-group">
                    <label htmlFor="">Tipo de Imagen:</label>
                    <select
                      className="form-select"
                      name='clasearchivo'
                      value={clasearchivo}
                      onChange={handleChange}>
                      <option value="" disabled>Selecciona un tipo de Archivo</option>
                      <option value="fotoperfil">Foto de Perfil</option>
                      <option value="incidencia">Incidencia</option>

                    </select>
                  </div>
                </div>
                <div className="col-xs-5 col-sm-5 col-md-5" >
                  <div className="form-group">
                    <label htmlFor="">Estado:</label>
                    <select
                      className="form-select"
                      name='clasearchivo'
                      value={estadofoto}
                      onChange={handleChangeestado}>
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <input class="form-control" type="file" accept="image/*" onChange={handleFileChange} style={{ width: '100%' }} />
              </div>

              {imagenPreview && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px',
                    marginBottom: '10px',

                  }}
                >
                  <img
                    src={imagenPreview}
                    alt="Vista previa"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '200px',
                    }}
                  />
                </div>
              )}
            </div>
            :
            <div style={{ marginTop: '10px' }}>
              <div className="alert alert-primary" role="alert">
                Formatos: PDF, Word, Excel, Power Point, TXT.
              </div>
              <div className="form-group">
                <label htmlFor="">Tipo de Archivo:</label>
                <select
                  className="form-select"
                  name='clasearchivo'
                  value={clasearchivo}
                  onChange={handleChange}>
                  <option value="" disabled>Selecciona un tipo de Archivo</option>
                  <option value="curriculum">Curriculum</option>
                  <option value="incidencia">Incidencia</option>
                  <option value="faltas">Faltas</option>

                </select>
              </div>
              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <input
                  class="form-control"
                  type="file"
                  accept=".pdf, .doc, .docx"
                  onChange={handleFileChange}
                  style={{ width: '100%' }} />
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <FormUsuario
        show={showFormUsuarioModal}
        onHide={() => {
          setShowFormUsuarioModal(false);
          clearEditUserData();
        }}
        initialData={isEditMode ? editUserData : null}
        onSubmitSuccess={handleOnSubmitSuccess}
      />

      <ModalImagenes
        show={showImagenesModal}
        onHideMI={() => {
          setShowImagenesModal(false);
        }}
        selectedImagen={selectedImagen}
      />


      <HistorialFaltas
        show={showHistorialFaltas}
        onHide={() => {
          setShowHistorialFaltas(false);
        }}
      />

      <FichaPersonal
        show={showFichaPersonal}
        onHide={() => {
          setShowFichaPersonal(false);
        }}
        selectedFichaPersona={selectedFichaPersona}
      />

      <RegistrosUsuario
        showModalTablaMes={showModalAsistencias}
        onClose={() => setShowModalAsistencias(false)}
        documento={selectedPersonaAsistencia}
      />


      <AsitenciasBitacora
        showModal={showModalBitAsis}
        onClose={() => setShowModalBitAsis(false)}
      />

      {/* ModalFotos recibe las props necesarias */}
      <ModalFotos
        showModal={showModalFotos}
        handleClose={handleCloseFotos}
        fecha={selectedFecha}
      />

      {/* ModalReportes recibe las props necesarias */}
      <ModalReporte
        showModal={showModalReporte}
        handleClose={handleCloseReporte}
        fecha={selectedFecha}
      />

    </div>
  )
}
/*FIN DE LA FUNCION PRINCIPAL */

export default PersonalSeguridad